.p-news{

  &__heading{
    position: relative;
  }

  &__list{
    margin-top: 7.2rem;
    @media (--mobile) {
      margin-top: 3.8rem;
    }

  }


  .wp-pagenavi{
    margin-top: 6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 32rem;
    margin-left: auto;
    margin-right: auto;

    @media (--mobile) {
      width: 24rem;
    }


    .page{
      font-size: 1.4rem;

    }
    .first,
    .last,
    .page{
      padding: 1rem;
      transition: 0.3s;
      border: 1px solid transparent;
      @media (--large) {
        &:hover{
          border: 1px solid #DBDBDB;
        }
      }
    }

    .current{
      border: 1px solid #DBDBDB;
      padding: 1rem;
      font-size: 1.6rem;
    }

    .previouspostslink,
    .nextpostslink{
      width: 2.8rem;
      height: 0.4rem;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .previouspostslink{
      background-image: url(../images/news/news-arrow-prev.svg);
      order: -10;
    }

    .nextpostslink{
      background-image: url(../images/news/news-arrow-next.svg);
      order: 10;
    }
  }

}