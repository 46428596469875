.p-store {
  &__heading {
    .heading {
      &-inner{

      }

      &-logo {
        svg {
          width: 18.1rem;
          height: 9.4rem;
          margin-top: 8.2rem;
          margin-right: auto;
          margin-left: auto;
          @media (--mobile) {
            margin-top: 6.2rem;
          }

          path {
            fill: #430E12;
          }
        }
      }
    }
  }

  &__info{
    position: relative;
    .info{
      &-inner{
        width: 85.5rem;
        margin-left: auto;
        margin-right: auto;
        @media (--mobile) {
          width: 100%;
        }
      }
    }
  }

  &__img {
    padding-top: 9rem;
    @media (--mobile) {
      padding-top: 1.7rem;
    }

    picture {
      width: calc(1300 / 1400 * 100%);
      height: auto;

      @media (--mobile) {
        width: calc(335 / 375 * 100%);
        height: 23rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__reserve {
    margin-top: 12.7rem;

    @media (--mobile) {
      margin-top: 8.6rem;
    }

    .reserve {
      &-ttl {
        font-size: 2.8rem;

        @media (--mobile) {
          font-size: 2.4rem;
        }
      }
      &-way {
        .way {

          &-content {
            display: flex;
            margin-top: 7rem;
            margin-top: 2.4rem;
            @media (--mobile) {
              margin-top: 0.8rem;
              flex-direction: column;
            }

            p {
              line-height: 1;
              @media (--mobile) {
                line-height: 2;
              }
            }

            &__tel {
              padding-top: 1.5rem;
              padding-right: 6.1rem;
              padding-bottom: 1.5rem;
              @media (--mobile) {
                padding-right: 0;
                padding-top: 2rem;
                padding-bottom: 2rem;
              }

              p {
                &:first-child {
                  font-size: 2rem;
                  @media (--mobile) {
                    font-size: 1.8rem;
                  }
                }

                &:last-child {
                  margin-top: 2em;
                  font-size: 1.6rem;
                  @media (--mobile) {
                    font-size: 1.4rem;
                    margin-top: 0rem;
                  }
                }
              }

              a {
                display: block;
                margin-top: 2.4rem;
                color:  #58574C;
                @media (--mobile) {
                  margin-top: 0rem;
                }

                span {
                  &:first-child {
                    font-size: 1.9rem;
                    @media (--mobile) {
                      font-size: 1.7rem;
                    }
                  }

                  &:last-child {
                    font-size: 3.1rem;
                    font-weight: bold;
                    @media (--mobile) {
                      font-size: 2.8rem;
                    }
                  }
                }
              }
            }

            &__sns {
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              padding-left: 8.6rem;
              border-left: 1px solid #dbdbdb;

              @media (--mobile) {
                border-top: 1px solid #dbdbdb;
                padding-left: 0;
                border-left: 0px;
                padding-top: 2rem;
                padding-bottom: 2rem;
              }

              p {
                &:first-child{
                }
                &:nth-child(2) {
                  margin-top: 1.5rem;
                  font-size: 1.4rem;
                }

                &:last-child {
                  display: flex;
                  align-items: center;
                  margin-top: 2.5rem;
                  @media (--mobile) {
                    justify-content: center;
                  }

                  .sns-icon {
                    display: flex;
                    align-items: center;

                    svg {
                      width: 2.4rem;
                      height: 2.3rem;
                      margin-right: 1rem;
                      path{
                        fill: #58574C;
                      }
                    }

                    &:nth-child(2) {
                      margin-left: 4.5rem;
                      @media (--mobile) {
                        margin-left: 2rem;
                      }
                    }
                  }
                }
              }
            }
          }

          &-caution {
            margin-top: 5rem;
            @media (--mobile) {
              margin-top: 2rem;
              span{
                display: block;
              }
            }
          }
        }
      }
      &-customer{
        margin-top: 9.3rem;

        @media (--mobile) {
          margin-top: 6.2rem;
        }

        ul{
          margin-top: 2.8rem;
          display: flex;
          flex-wrap: wrap;

          @media (--mobile) {
            margin-top: 1rem;
          }

          li{
            width: 53.5rem;
            padding-top: 3.2rem;
            padding-bottom: 3.2rem;
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid #DBDBDB;
            @media (--mobile) {
              padding-top: 2.4rem;
              padding-bottom: 2.4rem;
            }
            @media (--large) {
              &:nth-child(2n){
                margin-left: 3rem;
              }
            }

            span{
              line-height: 2;
              &:first-child{
                font-size: 1.4rem;
                color:#A5A5A5;
                margin-right: 2rem;
              }
              &:nth-child(2){

              }
            }
          }
        }
      }
    }
  }
}
