.c-page {
  padding-top: 18.3rem;
  padding-bottom: 20rem;
  letter-spacing: 0.2em;
  overflow: hidden;

  @media (--mobile) {
    padding-top: 12rem;
    padding-bottom: 10rem;
  }

  &__ttl {
    font-size: 2.6rem;
    text-align: center;

    small {
      display: block;
      margin-top: 1.8rem;
      font-size: 1.4rem;
    }
  }
  // 個別ページタイトル付近にある「煉」
  &-ren {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 44.6rem;
    height: 48.8rem;
    transform: translate(-50%, -50%) translateX(29rem) translateY(-12rem);
    @media (--mobile) {
      width: calc(44.6rem * 0.8);
      height: calc(48.8rem * 0.8);
      transform: translate(-50%, -50%) translateX(10rem) translateY(-20rem);
    }

    &.store-ren{
      top: 0;
      transform: translateY(-31rem);
      @media (--mobile) {
        width: calc(44.6rem * 0.8);
        height: calc(48.8rem * 0.8);
        transform: translate(-50%, -50%) translateX(10rem) translateY(-63rem);
      }
    }
    &.news-ren{
      top: 0;
      transform: translateY(-17.3rem);
      @media (--mobile) {
        width: calc(44.6rem * 0.8);
        height: calc(48.8rem * 0.8);
        transform: translate(-50%, -50%) translateX(7rem) translateY(2rem);
      }
    }
  }
}

// テキスト画像量並びのレイアウト
.c-page_contentList {
  .contents {
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      height: 56.3rem;
      margin-top: 10.8rem;

      &.mt-lg {
        margin-top: 20.2rem;

        @media (--mobile) {
          margin-top: 8rem;
        }
      }

      @media (--mobile) {
        flex-direction: column-reverse;
        height: auto;
      }

      @media (--mobile) {
        width: 100%;
      }

      &:nth-child(n + 2) {
        margin-top: 15rem;

        @media (--mobile) {
          margin-top: 6.2rem;
        }
      }

      &__text {
        .text {
          &-inner {
            width: 43rem;

            @media (--mobile) {
              width: 100%;
              margin-top: 3rem;
            }
          }

          &-ttl {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            line-height: 1.7;

            &.f-sm {
              span {
                font-size: 2.8rem;

                @media (--mobile) {
                  font-size: 2.3rem;
                }
              }
            }

            span {
              font-size: 3.2rem;

              @media (--mobile) {
                font-size: 2.7rem;
              }

              &.position {
                display: block;
                width: 100%;
                margin-bottom: 0.4rem;
                font-size: 1.4rem;

                @media (--mobile) {
                  margin-bottom: 0.8rem;
                }
              }
            }

            small {
              margin-left: 2rem;
              font-size: 1.2rem;
            }
          }

          &-content {
            margin-top: 5.5rem;

            @media (--mobile) {
              margin-top: 2.4rem;
            }

            p:nth-child(n + 2) {
              margin-top: 1.5rem;
            }
          }

          &-career {
            margin-top: 4.8rem;

            @media (--mobile) {
              margin-top: 2.4rem;
            }

            p {
              margin-bottom: 2rem;
              font-size: 1.2rem;
              color: #a5a5a5;
            }

            ul {
              li {
                margin-top: 1.4rem;
              }
            }
          }
        }
      }

      &__img {
        position: absolute;
        top: 0;
        right: 0;
        width: 67.9rem;
        height: 56.3rem;

        @media (--mobile) {
          position: relative;
          width: 100%;
          height: 24rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &.rev {
        .text-inner {
          margin-right: 0;
          margin-left: auto;
        }

        .contents-item__img {
          right: initial;
          left: 0;
        }
      }
    }
  }
}

// 店舗情報
.c-page__info{
  margin-top: 7rem;
  margin-bottom: 6.3rem;

  &.p-top__store{
    dl{
      margin-bottom: 3.3rem;
    }
  }

  &.store-info{

    @media (--mobile) {
      margin-top: 0;
    }
  }
  dl{
      letter-spacing: 0.1em;
      padding: 3rem 0;
      display: flex;
      border-bottom: 0.1rem solid #DBDBDB;

      &.recruit{
        dd{
          span{
            display: block;
            &:nth-child(n + 2){
              margin-top: 1.2rem;
            }
          }
        }
      }

      @media (--mobile) {
        flex-direction: column;
        align-items: start;
      }

    dt{
      width: 18rem;
      color: #A5A5A5;
    }
    dd{
      flex: 1;

      @media (--mobile) {
        line-height: 2;
        margin-top: 1rem;
      }

      &.open{
        & > span{
          display: block;

          &:nth-child(n + 2){
            margin-top: 1.2rem;
          }
        }

        &-time{
          width: 15.5rem;
        }
      }


      a{
        margin-top: 2rem;
        width: 14.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #DBDBDB;

        &.tel{
          border-bottom: 0px;
          margin-top: 0;
          width: 100%;
          display: block;
        }

        @media (--mobile) {
          width: 14rem;
        }

        p{
          font-size: 1.4rem;
        }

        svg{
          margin-left: 0.8rem;
          width: 1.4rem;
          height: 1.3rem;
        }
      }
    }
  }
}

// ニュース リスト
.c-page-news{
  .list{
    width: 85.5rem;
    margin-left: auto;
    margin-right: auto;
    @media (--mobile) {
      width: 100%;
    }

    &-item{
      padding-top: 3rem;
      padding-bottom: 3.6rem;
      border-bottom: 1px solid #DBDBDB;
      transition: 0.3s;

      @media (--large) {
        &:hover{
          border-bottom: 1px solid #58574C;
        }
      }

      @media (--mobile) {
        padding-top: 2.4rem;
        padding-bottom: 2.8rem;
      }

      &__ttl{
        display: flex;
        @media (--mobile) {
          flex-direction: column;
        }
        time{
          margin-top: 1.2rem;
          width: 10.5rem;
          font-size: 1.2rem;
          color: #A5A5A5;
        }
        p{
          flex: 1;
          padding-right: 11rem;
          position: relative;
          @media (--mobile) {
            margin-top: 1.2rem;
          }
          svg{
            position: absolute;
            width: 1.6rem;
            height: 0.8rem;
            top: 1.7rem;

            right: 2rem;

          }
        }
      }
      &__description{
        margin-top: 1.6rem;
        display: none;
        @media (--mobile) {
          margin-top: 0rem;
        }
        p{
          margin-left: 10.3rem;
          padding-right: 11rem;
          font-size: 1.4rem;
          &:nth-child(n + 2){
            padding-top: 2rem;
            @media (--mobile) {
              padding-top: 1rem;
            }
          }
          @media (--mobile) {
            margin-left: 0;
            padding-right: 0;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.not-found{
  text-align: center;
  margin-top: 3rem;
}