.c-i-inner{
  width: 110rem;
  margin-left: auto;
  margin-right: auto;

  @media (--mobile) {
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.c-o-inner{
  width: 120rem;
  margin-left: auto;
  margin-right: auto;
  @media (--mobile) {
    width: 100%;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}