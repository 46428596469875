.p-top{

  &__mv{
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #F4F3F2;
    &-logo {
      width: 13.6rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      filter: blur(10px);
      opacity: 0;
      svg {
        width: 100%;
        height: auto;
        path {
          fill: #58574C;
        }
      }
    }
    .mv{
      &-img{
        width: 100%;
        height: 100%;
        opacity: 0;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-ttl{
        width: 48.8rem;
        height: 23rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 4rem;
        text-align: center;
        line-height: 1.7;
        margin-top: 1.4rem;
        letter-spacing: 0.1em;
        opacity: 0;
        @media (--mobile) {
          font-size: 3.2rem;
          margin-top: 0;
          width: 29rem;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          &.is-after {
            opacity: 0;
          }
        }
      }
      &-copy{
        width: 36.2rem;
        height: 28.8rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &__inner{
          position: relative;
          width: 100%;
          height: 100%;
          p{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) translateY(-8rem);
            display: inline-block;
            color: #fff;
            font-weight: 500;
            line-height: 1;
            padding-bottom: 1.4rem;
            position: relative;
            @media (--mobile) {
              transform: translate(-50%, -50%) translateY(-7rem);
            }
            &::before{
              content: "";
              position: absolute;
              width: 100%;
              height: 1px;
              background: rgba($color: #fff, $alpha: .3);
              bottom: 0;


            }
          }
        }
        &__bg{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60.8rem;
          height: 24.4rem;
          opacity: 0;

          @media (--mobile) {
            width: 100%;
            height: 13rem;
            margin-left: 2rem;
            top: 46%;
          }
        }
      }
      &-scroll{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1rem;
        @media (--mobile) {
          bottom: 0;
        }
        p{
          font-size: 1.2rem;
          color: #fff;
          margin-bottom: 1rem;
        }
        &__bar {
          display: block;
          width: 1px;
          height: 3rem;
          overflow: hidden;
          position: relative;
          margin-inline: auto;
          @media (--mobile) {
            height: 2rem;
          }
          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 600%;
            background-color: white;
            position: absolute;
            left: 0;
            top: -600%;
            animation: barAnimation 4s infinite linear;
          }
        }
        @keyframes barAnimation{
          0% {
            top: -600%;
          }
          100%{
            top: 100%;
          }
        }
      }
    }
  }

  &__heading{
    padding-top: 10rem;
    padding-bottom: 10rem;
    @media (--mobile) {
      padding-top: 8.7rem;
      padding-bottom: 8rem;
      overflow: hidden;
    }
    .heading{
      &-inner{
        width: 43rem;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        @media (--mobile) {
          width: 100%;
        }
      }

      &-ttl{
        text-align: center;
        font-size: 2.8rem;
        @media (--mobile) {
          font-size: 2.4rem;
          line-height: 1.75;
        }
      }
      &-text{
        margin-top: 6.8rem;
        p{
          &:nth-child(n + 2){
            margin-top: 3.6rem;
            margin-bottom: 7.5rem;
          }
        }
      }
      &-img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) translateX(24rem);
        width: 41.1rem;
        height: 61.6rem;
        z-index: -1;

        @media (--mobile) {
          width: 41rem;
          height: 61.6rem;
          left: auto;
          right: 0;
          transform: translateX(9rem) translateY(-50%);
        }

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__cook-img{
    width: 100%;
    height: 51rem;
    @media (--mobile) {
      height: 23rem;
    }
    picture{
      width: calc(1300 / 1400 * 100%);
      height: 100%;

      @media (--mobile) {
        width: calc(335 / 375 * 100%);
      }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__menu{
    .menu{
      &-inner{
        margin-top: 10rem;
        padding-top: 22.3rem;
        padding-bottom: 56.2rem;
        margin-bottom: 9.6rem;
        position: relative;

        @media (--mobile) {
          margin-top: 8.6rem;
          margin-bottom: 8rem;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      &-content{

      }
      &-text{
        margin-top: 6.7rem;
        margin-bottom: 6.5rem;
        width: 43.2rem;
        @media (--mobile) {
          margin-top: 4.3rem;
          width: 100%;
        }

      }

      &-img{
        position: absolute;
        &-01{
          right: 0;
          top: 0;
          width: 57.5rem;
          height: 93rem;
          @media (--mobile) {
            width: 18.7rem;
            height: 33rem;
          }
        }
        &-02{
          left: 0;
          bottom: 0;
          width: 50.8rem;
          height: 31.4rem;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__menu-img{
    position: relative;
    height: 51.5rem;
    margin-bottom: 8.6rem;

    .menu{
      &-img{
        position: absolute;
        &-01{
          right: 0;
          top: 0;
          width: 18.7rem;
          height: 33rem;
        }
        &-02{
          left: 0;
          bottom: 0;
          width: 23.6rem;
          height: 14.5rem;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__news{
    .news{
      &-inner{
        width: 85.5rem;
        margin-right: auto;
        margin-left: auto;
        @media (--mobile) {
          width: 100%;
        }
      }
      &-list{
        margin-top: 3rem;
        margin-bottom: 6.3rem;
        @media (--mobile) {
          margin-top: 1rem;
          margin-bottom: 4.8rem;
        }
      }
      &-item{
        a{
          border-bottom: 1px solid #DBDBDB;
          position: relative;
          padding-top: 3rem;
          padding-bottom: 3rem;
          padding-right: 5rem;
          display: flex;
          align-items: center;

          @media (--mobile) {
            flex-direction: column;
            align-items: start;
          }

          time{
            margin-right: 4rem;
            font-size: 1.2rem;
            color: #A5A5A5;
            @media (--mobile) {
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
            }
          }

          svg{
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            width: 1.75rem;
            height: 0.38rem;
            @media (--mobile) {
              top: initial;
              transform: initial;
              bottom: 4rem;
            }
          }
        }
      }

    }
  }

  &__shop-img{
    height: 48rem;
    width: 100%;
    position: relative;
    margin-top: 11rem;
    margin-bottom: 11.5rem;
    @media (--mobile) {
      margin-top: 14rem;
      margin-bottom: 0rem;
    }

    @media (--mobile) {
      height: 38rem;
    }

    .shop-img{
      position: absolute;

      &-01{
        width: 22rem;
        height: 35.6rem;
        top: 0;
        left: 0;
        @media (--mobile) {
          width: 10.1rem;
          height: 16.3rem;
        }
      }
      &-02{
        width: 39.5rem;
        height: 24.4rem;
        left: 27.3rem;
        top: 23.4rem;
        @media (--mobile) {
          left: 0;
          width: 18.1rem;
          height: 11.2rem;
        }
      }
      &-03{
        top: 6rem;
        right: 0;
        width: 51.3rem;
        height: 31.8rem;
        @media (--mobile) {
          width: 22.4rem;
          height: 15.1rem;
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__store{
    margin-bottom: 10rem;
    @media (--mobile) {
      margin-top: -4rem;
    }
    .store{
      &-inner{
        width: 85.5rem;
        margin-left: auto;
        margin-right: auto;
        @media (--mobile) {
          width: 100%;
        }

      }
      &-info{
        margin-top: 3rem;
      }
    }
  }
}


.c-top{
  &-ttl{
    font-size: 2.1rem;
    position: relative;
    &::before{
      top: 50%;
      transform: translateY(-50%);
      left: -4.5rem;
      position: absolute;
      content: "";
      width: 3.3rem;
      height: 0.1rem;
      background-color: #DBDBDB;
    }
    span{
      font-size: 1.2rem;
      margin-left: 2rem;
    }
  }
}