.l-header {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 11rem;
  padding-top: 1.6rem;
  padding-right: 4.5rem;
  padding-bottom: 1.6rem;
  padding-left: 3rem;

  @media (--mobile) {
    height: 10rem;
    padding-top: 1.6rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  &.UpMove {
    animation: UpAnime 0.8s forwards;
  }

  @keyframes UpAnime {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(-100px);
    }
  }

  &.DownMove {
    animation: DownAnime 0.8s forwards;
  }

  @keyframes DownAnime {
    from {
      opacity: 0;
      transform: translateY(-100px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (--mobile) {
      align-items: flex-start;
    }
    &.is-top-delay {
      transition-delay: 3s;
    }
  }

  &__logo {
    display: block;
    width: 8.9rem;
    height: 11.6rem;

    @media (--mobile) {
      width: 8.1rem;
      height: 10.6rem;
    }

    &.is-active{
      svg{
        path{
          fill: #fff !important;
        }
      }
    }

  }

  &__nav {
    display: flex;
    align-items: center;

    .nav {
      &-sns {
        display: flex;
        align-items: center;
        transition: 0.3s;


        &.is-hidden {
          opacity: 0;
        }

        &__item {
          display: block;
          width: 2.26rem;
          height: 2.26rem;

          &:nth-child(n + 2){
            margin-left: 1.5rem;
          }

          svg{
            path{
              fill: #fff;
            }
          }
        }
      }

      &-hum {
        position: relative;
        width: 3.8rem;
        height: 1.7rem;
        margin-left: 4.1rem;

        @media (--mobile) {
          margin-left: 1.8rem;
        }


        &::before,
        &::after {
          position: absolute;
          width: 3.8rem;
          height: 0.1rem;
          content: "";
          background-color: #fff;
          transition: 0.5s;
        }

        &::before {
          top: 0;
        }

        &::after {
          top: 100%;
        }

        &.is-active {
          &::before,
          &::after {
            background-color: #fff;
            top: 50%;
            left: 50%;
          }

          &::before {
            transform: translate(-50%, -50%) rotate(30deg);
          }

          &::after {
            transform: translate(-50%, -50%) rotate(-30deg);
          }
        }
      }
    }
  }


  &-top{

    .l-header__logo{
      svg{
        filter: drop-shadow(0 0 0.75rem #58574C);
      }
    }


    .l-header__nav{
      svg{
        filter: drop-shadow(0 0 0.75rem #58574C);
      }
    }

    .nav-hum{
      cursor: pointer;
      &::after,
      &::before{
        box-shadow: 1px 1px 1px #A5A5A5;
      }
    }
  }

  &-page{
    .l-header__logo,
    .nav-sns__item{
      svg{
        filter: drop-shadow(0 0 0.1rem #A5A5A5);
        path{
          fill: #58574C;
        }
      }
    }
    .nav-hum{
      &::before,
      &::after{
        background-color:#58574C;
      }
    }
  }

}
.hum {
  &-container {
    position: relative;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    background: rgba($color: #430E12, $alpha: 0.9);
    opacity: 0;
    transition: 0.5s;
    @media (--mobile) {
      background: rgba($color: #430E12, $alpha: 1);
      overflow-y: scroll;
    }

    &.is-active {
      pointer-events: all;
      opacity: 1;
      z-index: 10;
    }
  }

  &-img-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: 0.5s;

    @media (--mobile) {
      z-index: 10;
      width: calc(355 / 375 * 100%);
      height: 25.5vh;
      top: 17rem;
      left: initial;
      bottom: initial;
    }

    &.is-active {
      opacity: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(520 / 1400 * 100%);
    height: 100%;
    background: rgba($color: #430E12, $alpha: 0.9);
    @media (--mobile) {
      width: calc(294 / 375 * 100%);
      height: 28rem;
      top: 38.5rem;
      left: 50%;
      right: initial;
      transform: translate(-50%, -50%) translateY(16rem);
    }

    &__logo {
      display: block;
      width: 11.6rem;
      height: 15.2rem;
      margin-top: 7rem;
      margin-right: auto;
      margin-left: auto;
    }

    &__nav {
      margin-top: 4.6rem;
      margin-bottom: 6.4rem;
      @media (--mobile) {
        margin-top: 0;

      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (--mobile) {
        flex-direction: row;
        flex-wrap: wrap;

      }
    }

    &__item {
      a {
        font-size: 1.6rem;
        color: #fff;
        font-weight: bold;
      }

      @media (--large) {
        &:nth-child(n + 2) {
          margin-top: 3rem;
        }
      }

      @media (--mobile) {
        width: 50%;
        text-align: center;

        &:nth-child(n + 3){
          margin-top: 3.6rem;
        }
      }
    }

    &__reserve {
      color: #fff;
      text-align: center;

      @media (--mobile) {
        padding-bottom: 4rem;
      }

      p{
        line-height: 1.5;
      }

      .reserve-text {
        &__ttl {
          position: relative;
          padding-bottom: 2.8rem;
          font-size: 2rem;

          @media (--mobile) {
            padding-bottom: 2.1rem;
            font-size: 1.8rem;
          }

          // &::before {
          //   position: absolute;
          //   bottom: 0;
          //   left: 50%;
          //   width: 7.2rem;
          //   height: 0.1rem;
          //   content: "";
          //   background: rgba($color: #fff, $alpha: 0.3);
          //   transform: translateX(-50%);
          // }
        }

        &__tel {
          display: block;
          color: #fff;
          span {
            &:first-child {
              font-size: 1.9rem;

              @media (--mobile) {
                font-size: 1.7rem;
              }
            }

            &:nth-child(2) {
              margin-left: 0.7rem;
              font-size: 3.1rem;

              @media (--mobile) {
                font-size: 2.9rem;
              }
            }
          }
        }

        &__time {
          font-size: 1.6rem;

          @media (--mobile) {
            font-size: 1.4rem;
          }
        }

        &__caution {
          margin-top: 2rem;
          font-size: 1.4rem;

          @media (--mobile) {
            margin-top: 1.6rem;
          }
        }

        &__sns{
          margin-top: 3.3rem;
          margin-left: auto;
          margin-right: auto;
          padding: 1.2rem 1.6rem;
          background: rgba($color: #fff, $alpha: 0.1);
          width: 38rem;
          @media (--mobile) {
            padding: 1.2rem 1.4rem;
            font-size: 1.2rem;
            margin-left: initial;
            margin-right: initial;
            width: 100%;
          }
        }
      }
    }

    &__sns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 6rem;
      @media (--mobile) {
        margin-top: 0rem;
        padding-bottom: 5rem;
      }

      a {
        display: inline-block;
        &:nth-child(n + 2){
          margin-left: 1.7rem;
        }
        svg {
          width: 2.26rem;
          height: 2.26rem;

          path{
            fill: #fff;
          }
        }
      }
    }
  }
}
