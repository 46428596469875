.more-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 15rem;

  &.canter {
    margin: 0 auto;
    justify-content: center;

    &::before {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.right {
    margin-right: 0;
    margin-left: auto;
  }

  p {
    font-size: 1.2rem;
  }

  svg {
    width: 2.8rem;
    height: 0.38rem;
    margin-left: 1rem;
  }

  &::before {
    position: absolute;
    bottom: -0.5rem;
    width: 12.6rem;
    height: 0.1rem;
    content: "";
    background-color: #dbdbdb;
  }
}
