.js-acco-open {
  cursor: pointer;
  & > .list-item__ttl{
    & > p{
      & > svg{
        transition: 0.5s;
      }
    }
 }


  &.is-active{
   & > .list-item__ttl{
      & > p{
        & > svg{
          transform: rotate(-180deg);
        }
      }
   }
  }
}
