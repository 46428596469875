.p-menu {
  &__heading {
    .heading {
      &-inner{

      }

      &-img {
        width: calc(1300 / 1400 * 100%);
        margin-top: 8.2rem;

        @media (--mobile) {
          width: 100%;
          height: 24rem;
          margin-top: 4.2rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &__container {
    margin-top: 10.5rem;

    @media (--mobile) {
      margin-top: 8rem;
    }

    .menu {
      padding-bottom: 5rem;
      @media (--mobile) {
        padding-bottom: 0;
      }
      &-course {
        &:nth-child(n + 2) {
          margin-top: 15.6rem;

          @media (--mobile) {
            margin-top: 7rem;
          }
        }

        h2 {
          position: relative;
          display: flex;
          align-items: center;

          span {
            font-size: 2.1rem;
          }

          small {
            margin-left: 2rem;
            font-size: 1.2rem;
          }

          &::before {
            position: absolute;
            top: 50%;
            left: -4.5rem;
            width: 3.3rem;
            height: 0.1rem;
            content: "";
            background-color: #dbdbdb;
            transform: translateY(-50%);
          }
        }
      }

      &-course.season {
        .menu {

          &-content{
            margin-top: 5.8rem;
            @media (--mobile) {
              margin-top: 4.2rem;
            }

            &.puffer{
              margin-top: 9rem;
              @media (--mobile) {
                margin-top: 5rem;
              }
            }

            &.select{
              padding: 5rem 5.5rem;
              background-color: rgba($color: #fff, $alpha: 0.6);
              @media (--mobile) {
                padding: 2.5rem;
              }

            }

            h3{
              display: flex;
              align-items: center;
              letter-spacing: 0.2em;
              border-bottom: 1px solid #DBDBDB;
              padding-bottom: 1.3rem;
              span{
                font-size: 1.8rem;
              }
              small{
                font-size: 1.4rem;
                margin-left: 1rem;
              }
            }
            &__text{
              margin-top: 4rem;
              font-size: 1.4rem;
            }


            .content{
              &-puffer{
                  padding: 5rem 5.5rem;
                  background-color: rgba($color: #fff, $alpha: 0.6);
                  width: 52rem;
                  margin-top: 4rem;

                  @media (--mobile) {
                    padding: 2.5rem;
                    width: 100%;
                    margin-top: 2.4rem;
                  }

                &__ttl{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  h4{
                    font-size: 1.8rem;
                  }

                }
                &__corse{
                  margin-top: 2rem;
                }
                &__text{
                  margin-top: 2.4rem;
                  p{
                    line-height: 1.8;
                  }
                }
              }
            }


          }

          &-list {
            width: 100%;
            margin-top: 4rem;
            flex-direction: row;
            @media (--mobile) {
              margin-top: 2.4rem;
            }
          }

          &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 23rem;
            height: 23rem;
            background-color: rgba($color: #fff, $alpha: 0.6);

            &:nth-child(n + 2){
              margin-top: 0;
            }

            @media (--mobile) {
              width: 14rem;
              height: 14rem;
              padding: 2rem;
              &:nth-child(n + 3){
                margin-top: 2rem;
              }
            }

            @media (--large) {
              &:nth-child(n + 3) {
                margin-top: 0;
              }
            }
            &__price {
              margin-top: 0.8rem;
              text-align: center;
              @media (--mobile) {
                text-align: right;
              }
            }
          }
        }
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 5.3rem;

        @media (--mobile) {
          flex-direction: column;
          width: 100%;
          margin-top: 3.4rem;
        }
      }

      &-item {
        width: 52rem;
        height: auto;
        background-color: rgba($color: #fff, $alpha: 0.6);
        padding: 5rem 3.5rem 5rem 5rem;

        @media (--mobile) {
          padding: 2.5rem 2.5rem;
          height: auto;
        }

        &.no-white{
          background-color: transparent;
        }
        &.no-space{
          padding-left: 0;
          padding-right: 0;
          @media (--mobile) {
            padding-bottom: 0;
            margin-top: 0 !important;
          }
        }

        @media (--large) {
          &:nth-child(n + 3) {
            margin-top: 5rem;
          }
        }

        @media (--mobile) {
          width: 100%;

          &:nth-child(n + 2) {
            margin-top: 4.2rem;
            @media (--mobile) {
              margin-top: 3rem;
            }
          }
        }

        &__ttl {
          font-size: 1.8rem;

          &.main{
            display: flex;
            align-items: center;
            justify-content: space-between;
            span{
              &.price{
                font-size: 1.5rem;
                margin-right: 4.5rem;
              }
            }
          }

          @media (--mobile) {
            font-size: 1.7rem;
          }
        }

        &__text {
          margin-top: 3.3rem;
          font-size: 1.5rem;

          @media (--mobile) {
            margin-top: 2rem;
          }

          &.m-0{
            margin-top: 0;
            @media (--mobile) {
              margin-top: 0;
            }
          }

          & > span {
            display: block;
            font-size: 1.4rem;

            &:nth-child(n + 2) {
              margin-top: 1.5rem;

              @media (--mobile) {
                margin-top: 1.2rem;
              }
            }
          }
        }

        &__price {
          margin-top: 6rem;
          text-align: right;

          @media (--mobile) {
            margin-top: 0.8rem;
          }
        }
      }
    }
  }
}
