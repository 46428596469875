@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600;700&display=swap");
/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */
/**
 * for mobile viewoport
 */
@import url(normalize.css);
@custom-media --mobile screen and (max-width: 767px);
@custom-media --large screen and (min-width: 768px);
@custom-media --tablet screen and (max-width: 1023px) and (min-width: 768px);
@custom-media --vw_outside screen and (min-width: 1600px);
@custom-media --vw_inside screen and (min-width: 768px) and (max-width: 1599px);
/** http://bourbon.io/docs/#timing-functions */
/** example @include transition(all 5s $ease-in-circ);*/
/**
transition timing-function:
ease - cubic-bezier(0.25, 0.1, 0.25, 1.0) を指定したのと同じ（開始と完了を滑らかにする）（初期値）
linear - cubic-bezier(0.0, 0.0, 1.0, 1.0) を指定したのと同じ（一定）
ease-in - cubic-bezier(0.42, 0, 1.0, 1.0) を指定したのと同じ（ゆっくり始まる）
ease-out - cubic-bezier(0, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり終わる）
ease-in-out - cubic-bezier(0.42, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり始まってゆっくり終わる）
*/
/**
 * text ellipsis for block multiline
 */
/*
 unsupport IE, Edge, Fx

@mixin text-ellipsis-block($rows) {
  display: box;
  box-orient: vertical;
  line-clamp: $rows;
  overflow: hidden;
}
*/
/**
 * element size
 */
/**
 * position layout
 */
/**
 * images
 */
/**
* Variables
*/
/** extend */
@media (--large) {
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear; }
    .ex-link:hover {
      opacity: .5; } }

/* ========= 1文字ずつ出現させるためのCSS =============== */
.eachTextAnime span {
  opacity: 0; }

.eachTextAnime.appeartext span {
  animation: text_anime_on 1.4s ease-out forwards; }

@keyframes text_anime_on {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.js-inview[data-inview="fade"] {
  opacity: 0;
  transition: opacity 0.3s; }
  .js-inview[data-inview="fade"].is-inview {
    opacity: 1; }

.js-inview[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 1s, transform 1s;
  transform: translateY(30px); }
  .js-inview[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview[data-inview="fade-left"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(-30px); }
  .js-inview[data-inview="fade-left"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateX(30px); }
  .js-inview[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview[data-inview="btn"] a::before,
.js-inview[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn"] i {
  opacity: 0;
  transition: opacity 0.5s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn"].is-inview a::before,
.js-inview[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="btn"].is-inview i {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="btn-border"] a::after,
.js-inview[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview[data-inview="btn-border"].is-inview a::after,
.js-inview[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview[data-inview="bg"]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  transition: transform 0.4s;
  transform: scale(1, 1);
  transform-origin: right top; }

.js-inview[data-inview="bg"].is-inview::after {
  transform: scale(0, 1); }

.js-inview[data-inview="bg-white"]::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  transition: transform 0.4s;
  transform: scale(1, 1);
  transform-origin: right top; }

.js-inview[data-inview="bg-white"].is-inview::after {
  transform: scale(0, 1); }

.js-inview[data-inview="bg-scale"]::before {
  transition: transform 0.8s;
  transform: translate(-50%, -50%) scale(0.5);
  transform-origin: center center; }

.js-inview[data-inview="bg-scale"].is-inview::before {
  transform: translate(-50%, -50%) scale(1); }

.js-inview-photo[data-inview="scale-small"] {
  transition: transform 1s;
  transform: scale(1.5); }
  .is-inview .js-inview-photo[data-inview="scale-small"] {
    transform: scale(1); }

.js-inview-photo-img[data-inview="scale-small"] {
  transition: transform 1s;
  transform: translateX(-50%) scale(1.5); }
  .is-inview .js-inview-photo-img[data-inview="scale-small"] {
    transform: translateX(-50%) scale(1); }

.js-inview-eachItem[data-inview="fade"] {
  opacity: 0;
  transition: opacity 1.2s, text-shadow 0.8s, border-bottom 0.5s;
  text-shadow: 2px 2px #58574C; }
  .js-inview-eachItem[data-inview="fade"].is-inview {
    opacity: 1;
    text-shadow: initial; }
  .js-inview-eachItem[data-inview="fade"].delay {
    transition-delay: 1.8s; }

.js-inview-eachItem[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }
  .js-inview-eachItem[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview-eachItem[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(30px); }
  .js-inview-eachItem[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateX(0); }

.js-inview-eachItem[data-inview="btn"] a::before,
.js-inview-eachItem[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-eachItem[data-inview="btn"].is-inview a::before,
.js-inview-eachItem[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview-eachItem[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="btn-border"] a::after,
.js-inview-eachItem[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-eachItem[data-inview="btn-border"].is-inview a::after,
.js-inview-eachItem[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview-eachItem[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="list-border"]::before {
  transition: transform 0.8s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-eachItem[data-inview="list-border"] span,
.js-inview-eachItem[data-inview="list-border"] figure,
.js-inview-eachItem[data-inview="list-border"] a {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }

.js-inview-eachItem[data-inview="list-border"] i {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(-20px) rotate(45deg); }

.js-inview-eachItem[data-inview="list-border"].is-inview::before {
  transform: scale(1); }

.js-inview-eachItem[data-inview="list-border"].is-inview span,
.js-inview-eachItem[data-inview="list-border"].is-inview figure,
.js-inview-eachItem[data-inview="list-border"].is-inview a {
  opacity: 1;
  transform: translateY(0); }

.js-inview-eachItem[data-inview="list-border"].is-inview i {
  opacity: 1;
  transform: translateX(-10px) rotate(45deg); }

.js-inview-eachItem-fast[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  transform: translateY(10px); }
  .js-inview-eachItem-fast[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade"] {
  opacity: 0;
  transition: transform 0.8s; }
  .js-inview-eachItem-delay[data-inview="fade"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-top"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(30px); }
  .js-inview-eachItem-delay[data-inview="fade-top"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-right"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateX(30px); }
  .js-inview-eachItem-delay[data-inview="fade-right"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-eachItem-delay[data-inview="fade-bottom"] {
  opacity: 0;
  transition: opacity 0.8s, transform 0.8s;
  transform: translateY(-30px); }
  .js-inview-eachItem-delay[data-inview="fade-bottom"].is-inview {
    opacity: 1;
    transform: translateY(0); }

.js-inview-all[data-inview="btn"] a::before,
.js-inview-all[data-inview="btn"] button::before {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-all[data-inview="btn"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-all[data-inview="btn"].is-inview a::before,
.js-inview-all[data-inview="btn"].is-inview button::before {
  transform: scale(1, 1); }

.js-inview-all[data-inview="btn"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

.js-inview-all[data-inview="btn-border"] a::after,
.js-inview-all[data-inview="btn-border"] button::after {
  transition: transform 0.4s;
  transform: scale(0, 1);
  transform-origin: left top; }

.js-inview-all[data-inview="btn-border"] span {
  opacity: 0;
  transition: opacity 0.8s 0.3s, transform 0.5s 0.3s;
  transform: translateY(10px); }

.js-inview-all[data-inview="btn-border"].is-inview a::after,
.js-inview-all[data-inview="btn-border"].is-inview button::after {
  transform: scale(1, 1); }

.js-inview-all[data-inview="btn-border"].is-inview span {
  opacity: 1;
  transform: translateY(0); }

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo[data-inview="scale-small"] {
  transform: scale(1); }

_:-ms-lang(x)::-ms-backdrop,
.js-inview-photo-img[data-inview="scale-small"] {
  transform: translateX(-50%) scale(1); }

/* == @import "../node_modules/normalize.css/normalize.css"; */
[v-cloak] {
  display: none; }

@media (--large) {
  html {
    font-size: calc(var(--vw) / 140); } }

@media (--mobile) {
  html {
    font-size: calc(10vw / 375 * 100); } }

body {
  font-family: "Shippori Mincho", serif;
  font-size: 1.5rem;
  font-feature-settings: "palt";
  letter-spacing: 0.1em;
  background-color: #F0EEEA;
  font-weight: 700;
  color: #58574C;
  overflow-x: hidden; }
  body.is-open {
    overflow: hidden; }
  @media (--mobile) {
    body {
      font-size: 1.4rem; } }

a {
  color: #000;
  text-decoration: none; }

p {
  line-height: 2.4; }

picture {
  display: block; }

svg {
  display: block;
  width: 100%;
  height: 100%; }

*,
*::before,
*::after {
  box-sizing: border-box; }

h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
p {
  padding: 0;
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit; }

ul,
ol,
li {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

img {
  vertical-align: bottom; }

figure {
  padding: 0;
  margin: 0; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0; }

input[type="number"] {
  appearance: textfield; }

.l-main {
  line-height: 1.2; }

svg,
img {
  max-width: 100%; }

@media (--mobile) {
  html,
  body {
    width: 100%;
    height: 100%; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  dl,
  dt,
  dd,
  p {
    padding: 0;
    margin: 0; }
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%; } }

@media (--mobile) {
  .pc {
    display: none !important; } }

@media (--large) {
  [href*="tel:"] {
    pointer-events: none; }
  .sp {
    display: none !important; } }

.l-footer__reserve .reserve-inner {
  position: relative; }

.l-footer__reserve .reserve-img {
  width: 100%;
  height: 52rem; }
  .l-footer__reserve .reserve-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.l-footer__reserve .reserve-content {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 100%;
  justify-content: center;
  padding-top: 6.5rem;
  padding-bottom: 6rem; }
  .l-footer__reserve .reserve-content h3 {
    font-size: 2.4rem;
    margin-bottom: 10rem; }
    @media (--mobile) {
      .l-footer__reserve .reserve-content h3 {
        font-size: 1.8rem;
        margin-bottom: 3.4rem; } }

.l-footer__reserve .reserve-content__inner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center; }
  @media (--mobile) {
    .l-footer__reserve .reserve-content__inner {
      flex-direction: column; } }

.l-footer__reserve .reserve-text {
  padding-right: 10rem;
  padding-left: 10rem; }
  @media (--mobile) {
    .l-footer__reserve .reserve-text {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 3rem; } }
  .l-footer__reserve .reserve-text p {
    line-height: 1; }
  .l-footer__reserve .reserve-text__ttl {
    position: relative;
    padding-bottom: 3rem;
    font-size: 2rem; }
    @media (--mobile) {
      .l-footer__reserve .reserve-text__ttl {
        font-size: 1.8rem;
        padding-bottom: 2.1rem; } }
    .l-footer__reserve .reserve-text__ttl::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 7.2rem;
      height: 0.1rem;
      content: "";
      background: rgba(255, 255, 255, 0.3);
      transform: translateX(-50%); }
  .l-footer__reserve .reserve-text__tel {
    margin-top: 2.74rem;
    display: block;
    color: #fff; }
    @media (--mobile) {
      .l-footer__reserve .reserve-text__tel {
        margin-top: 1.5rem; } }
    .l-footer__reserve .reserve-text__tel span:first-child {
      font-size: 1.9rem; }
      @media (--mobile) {
        .l-footer__reserve .reserve-text__tel span:first-child {
          font-size: 1.7rem; } }
    .l-footer__reserve .reserve-text__tel span:nth-child(2) {
      margin-left: 0.7rem;
      font-size: 3.4rem; }
      @media (--mobile) {
        .l-footer__reserve .reserve-text__tel span:nth-child(2) {
          font-size: 2.9rem; } }
  .l-footer__reserve .reserve-text__time {
    margin-top: 1.36rem;
    font-size: 1.6rem; }
    @media (--mobile) {
      .l-footer__reserve .reserve-text__time {
        margin-top: 1.5em;
        font-size: 1.4rem; } }
  .l-footer__reserve .reserve-text__caution {
    font-size: 2rem; }
    @media (--mobile) {
      .l-footer__reserve .reserve-text__caution {
        font-size: 1.4rem;
        margin-top: 1.6rem; } }

.l-footer__reserve .reserve-sns {
  padding-left: 10rem;
  border-left: 1px solid rgba(255, 255, 255, 0.3); }
  @media (--mobile) {
    .l-footer__reserve .reserve-sns {
      border-left: 0;
      padding-left: 0;
      padding-top: 3rem;
      border-top: 1px solid rgba(255, 255, 255, 0.3); } }
  .l-footer__reserve .reserve-sns__text {
    text-align: left; }
    @media (--mobile) {
      .l-footer__reserve .reserve-sns__text {
        font-size: 1.2rem; } }
    .l-footer__reserve .reserve-sns__text span {
      display: block; }
      .l-footer__reserve .reserve-sns__text span:nth-child(n + 2) {
        font-size: 1.4rem; }
        @media (--mobile) {
          .l-footer__reserve .reserve-sns__text span:nth-child(n + 2) {
            font-size: 1.2rem; } }
  .l-footer__reserve .reserve-sns__link {
    display: flex;
    margin-top: 3.7rem; }
    @media (--mobile) {
      .l-footer__reserve .reserve-sns__link {
        justify-content: center;
        margin-top: 2.8rem; } }
    .l-footer__reserve .reserve-sns__link a {
      display: flex;
      align-items: center; }
      .l-footer__reserve .reserve-sns__link a:nth-child(n + 2) {
        margin-left: 4.5rem; }
        @media (--mobile) {
          .l-footer__reserve .reserve-sns__link a:nth-child(n + 2) {
            margin-left: 2.4rem; } }
      .l-footer__reserve .reserve-sns__link a span {
        color: #fff;
        margin-left: 1.5rem; }
      .l-footer__reserve .reserve-sns__link a svg {
        width: 2.38rem;
        height: 2.28rem; }
        .l-footer__reserve .reserve-sns__link a svg path {
          fill: #fff; }

.l-footer__body {
  height: 25rem;
  padding-top: 5.4rem;
  padding-bottom: 6.3rem;
  background-color: #430E12;
  position: relative; }
  @media (--mobile) {
    .l-footer__body {
      height: 38rem; } }
  .l-footer__body .body-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (--mobile) {
      .l-footer__body .body-inner {
        flex-direction: column; } }
  .l-footer__body .body-left__logo {
    width: 8.4rem;
    height: 11rem;
    display: block; }
    .l-footer__body .body-left__logo img {
      width: 100%;
      height: 100%; }
  .l-footer__body .body-right__list {
    display: flex; }
    @media (--mobile) {
      .l-footer__body .body-right__list {
        flex-wrap: wrap;
        margin-top: 4rem; } }
    @media (--large) {
      .l-footer__body .body-right__list li:nth-child(n + 2) {
        margin-left: 3rem; } }
    @media (--mobile) {
      .l-footer__body .body-right__list li {
        width: 50%;
        text-align: center; }
        .l-footer__body .body-right__list li:nth-child(n + 3) {
          margin-top: 3rem; } }
    .l-footer__body .body-right__list li a {
      font-size: 1.6rem;
      color: #fff; }
  .l-footer__body .body-sns {
    position: absolute;
    bottom: -6rem;
    left: 0;
    display: flex;
    margin-top: 2.7rem; }
    .l-footer__body .body-sns a {
      display: inline-block; }
      .l-footer__body .body-sns a:nth-child(n + 2) {
        margin-left: 1.5rem; }
    @media (--mobile) {
      .l-footer__body .body-sns {
        bottom: -10rem; } }
    .l-footer__body .body-sns svg {
      width: 2.7rem;
      height: 2.7rem; }
      @media (--mobile) {
        .l-footer__body .body-sns svg {
          width: 2.2rem;
          height: 2.2rem; } }
      .l-footer__body .body-sns svg path {
        fill: #fff; }
  .l-footer__body .body-copy {
    position: absolute;
    right: 0;
    bottom: -6rem;
    font-size: 1.1rem;
    color: #fff; }
    @media (--mobile) {
      .l-footer__body .body-copy {
        bottom: -10rem; } }

.l-header {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 11rem;
  padding-top: 1.6rem;
  padding-right: 4.5rem;
  padding-bottom: 1.6rem;
  padding-left: 3rem; }
  @media (--mobile) {
    .l-header {
      height: 10rem;
      padding-top: 1.6rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem; } }
  .l-header.UpMove {
    animation: UpAnime 0.8s forwards; }

@keyframes UpAnime {
  from {
    opacity: 1;
    transform: translateY(0); }
  to {
    opacity: 0;
    transform: translateY(-100px); } }
  .l-header.DownMove {
    animation: DownAnime 0.8s forwards; }

@keyframes DownAnime {
  from {
    opacity: 0;
    transform: translateY(-100px); }
  to {
    opacity: 1;
    transform: translateY(0); } }
  .l-header__inner {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media (--mobile) {
      .l-header__inner {
        align-items: flex-start; } }
    .l-header__inner.is-top-delay {
      transition-delay: 3s; }
  .l-header__logo {
    display: block;
    width: 8.9rem;
    height: 11.6rem; }
    @media (--mobile) {
      .l-header__logo {
        width: 8.1rem;
        height: 10.6rem; } }
    .l-header__logo.is-active svg path {
      fill: #fff !important; }
  .l-header__nav {
    display: flex;
    align-items: center; }
    .l-header__nav .nav-sns {
      display: flex;
      align-items: center;
      transition: 0.3s; }
      .l-header__nav .nav-sns.is-hidden {
        opacity: 0; }
      .l-header__nav .nav-sns__item {
        display: block;
        width: 2.26rem;
        height: 2.26rem; }
        .l-header__nav .nav-sns__item:nth-child(n + 2) {
          margin-left: 1.5rem; }
        .l-header__nav .nav-sns__item svg path {
          fill: #fff; }
    .l-header__nav .nav-hum {
      position: relative;
      width: 3.8rem;
      height: 1.7rem;
      margin-left: 4.1rem; }
      @media (--mobile) {
        .l-header__nav .nav-hum {
          margin-left: 1.8rem; } }
      .l-header__nav .nav-hum::before, .l-header__nav .nav-hum::after {
        position: absolute;
        width: 3.8rem;
        height: 0.1rem;
        content: "";
        background-color: #fff;
        transition: 0.5s; }
      .l-header__nav .nav-hum::before {
        top: 0; }
      .l-header__nav .nav-hum::after {
        top: 100%; }
      .l-header__nav .nav-hum.is-active::before, .l-header__nav .nav-hum.is-active::after {
        background-color: #fff;
        top: 50%;
        left: 50%; }
      .l-header__nav .nav-hum.is-active::before {
        transform: translate(-50%, -50%) rotate(30deg); }
      .l-header__nav .nav-hum.is-active::after {
        transform: translate(-50%, -50%) rotate(-30deg); }
  .l-header-top .l-header__logo svg {
    filter: drop-shadow(0 0 0.75rem #58574C); }
  .l-header-top .l-header__nav svg {
    filter: drop-shadow(0 0 0.75rem #58574C); }
  .l-header-top .nav-hum {
    cursor: pointer; }
    .l-header-top .nav-hum::after, .l-header-top .nav-hum::before {
      box-shadow: 1px 1px 1px #A5A5A5; }
  .l-header-page .l-header__logo svg,
  .l-header-page .nav-sns__item svg {
    filter: drop-shadow(0 0 0.1rem #A5A5A5); }
    .l-header-page .l-header__logo svg path,
    .l-header-page .nav-sns__item svg path {
      fill: #58574C; }
  .l-header-page .nav-hum::before, .l-header-page .nav-hum::after {
    background-color: #58574C; }

.hum-container {
  position: relative;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  background: rgba(67, 14, 18, 0.9);
  opacity: 0;
  transition: 0.5s; }
  @media (--mobile) {
    .hum-container {
      background: #430e12;
      overflow-y: scroll; } }
  .hum-container.is-active {
    pointer-events: all;
    opacity: 1;
    z-index: 10; }

.hum-img-container {
  position: relative;
  width: 100%;
  height: 100%; }

.hum-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: 0.5s; }
  @media (--mobile) {
    .hum-img {
      z-index: 10;
      width: calc(355 / 375 * 100%);
      height: 25.5vh;
      top: 17rem;
      left: initial;
      bottom: initial; } }
  .hum-img.is-active {
    opacity: 1; }
  .hum-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.hum-content {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(520 / 1400 * 100%);
  height: 100%;
  background: rgba(67, 14, 18, 0.9); }
  @media (--mobile) {
    .hum-content {
      width: calc(294 / 375 * 100%);
      height: 28rem;
      top: 38.5rem;
      left: 50%;
      right: initial;
      transform: translate(-50%, -50%) translateY(16rem); } }
  .hum-content__logo {
    display: block;
    width: 11.6rem;
    height: 15.2rem;
    margin-top: 7rem;
    margin-right: auto;
    margin-left: auto; }
  .hum-content__nav {
    margin-top: 4.6rem;
    margin-bottom: 6.4rem; }
    @media (--mobile) {
      .hum-content__nav {
        margin-top: 0; } }
  .hum-content__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    @media (--mobile) {
      .hum-content__list {
        flex-direction: row;
        flex-wrap: wrap; } }
  .hum-content__item a {
    font-size: 1.6rem;
    color: #fff;
    font-weight: bold; }
  @media (--large) {
    .hum-content__item:nth-child(n + 2) {
      margin-top: 3rem; } }
  @media (--mobile) {
    .hum-content__item {
      width: 50%;
      text-align: center; }
      .hum-content__item:nth-child(n + 3) {
        margin-top: 3.6rem; } }
  .hum-content__reserve {
    color: #fff;
    text-align: center; }
    @media (--mobile) {
      .hum-content__reserve {
        padding-bottom: 4rem; } }
    .hum-content__reserve p {
      line-height: 1.5; }
    .hum-content__reserve .reserve-text__ttl {
      position: relative;
      padding-bottom: 2.8rem;
      font-size: 2rem; }
      @media (--mobile) {
        .hum-content__reserve .reserve-text__ttl {
          padding-bottom: 2.1rem;
          font-size: 1.8rem; } }
    .hum-content__reserve .reserve-text__tel {
      display: block;
      color: #fff; }
      .hum-content__reserve .reserve-text__tel span:first-child {
        font-size: 1.9rem; }
        @media (--mobile) {
          .hum-content__reserve .reserve-text__tel span:first-child {
            font-size: 1.7rem; } }
      .hum-content__reserve .reserve-text__tel span:nth-child(2) {
        margin-left: 0.7rem;
        font-size: 3.1rem; }
        @media (--mobile) {
          .hum-content__reserve .reserve-text__tel span:nth-child(2) {
            font-size: 2.9rem; } }
    .hum-content__reserve .reserve-text__time {
      font-size: 1.6rem; }
      @media (--mobile) {
        .hum-content__reserve .reserve-text__time {
          font-size: 1.4rem; } }
    .hum-content__reserve .reserve-text__caution {
      margin-top: 2rem;
      font-size: 1.4rem; }
      @media (--mobile) {
        .hum-content__reserve .reserve-text__caution {
          margin-top: 1.6rem; } }
    .hum-content__reserve .reserve-text__sns {
      margin-top: 3.3rem;
      margin-left: auto;
      margin-right: auto;
      padding: 1.2rem 1.6rem;
      background: rgba(255, 255, 255, 0.1);
      width: 38rem; }
      @media (--mobile) {
        .hum-content__reserve .reserve-text__sns {
          padding: 1.2rem 1.4rem;
          font-size: 1.2rem;
          margin-left: initial;
          margin-right: initial;
          width: 100%; } }
  .hum-content__sns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6rem; }
    @media (--mobile) {
      .hum-content__sns {
        margin-top: 0rem;
        padding-bottom: 5rem; } }
    .hum-content__sns a {
      display: inline-block; }
      .hum-content__sns a:nth-child(n + 2) {
        margin-left: 1.7rem; }
      .hum-content__sns a svg {
        width: 2.26rem;
        height: 2.26rem; }
        .hum-content__sns a svg path {
          fill: #fff; }

/**
 * main.scss
 */
.js-acco-open {
  cursor: pointer; }
  .js-acco-open > .list-item__ttl > p > svg {
    transition: 0.5s; }
  .js-acco-open.is-active > .list-item__ttl > p > svg {
    transform: rotate(-180deg); }

.more-btn {
  position: relative;
  display: flex;
  align-items: center;
  width: 15rem; }
  .more-btn.canter {
    margin: 0 auto;
    justify-content: center; }
    .more-btn.canter::before {
      left: 50%;
      transform: translateX(-50%); }
  .more-btn.right {
    margin-right: 0;
    margin-left: auto; }
  .more-btn p {
    font-size: 1.2rem; }
  .more-btn svg {
    width: 2.8rem;
    height: 0.38rem;
    margin-left: 1rem; }
  .more-btn::before {
    position: absolute;
    bottom: -0.5rem;
    width: 12.6rem;
    height: 0.1rem;
    content: "";
    background-color: #dbdbdb; }

.c-row {
  display: flex; }

@media (--large) {
  .text-hov svg {
    transition: 0.5s; }
    .text-hov svg path {
      transition: 0.3s; }
  .text-hov:hover svg {
    transform: translateX(1rem); }
    .text-hov:hover svg path {
      stroke: #58574C; }
  .icon-hov svg {
    transition: 0.3s; }
  .icon-hov:hover svg {
    transform: translateY(-0.3rem); }
  .footer-hov {
    transition: 0.8s;
    border-bottom: 1px solid transparent;
    padding-bottom: 0.5rem; }
    .footer-hov:hover {
      border-bottom: 1px solid #fff;
      text-shadow: 1px 1px 1px #58574C; } }

.c-google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden; }

.c-google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

.c-i-inner {
  width: 110rem;
  margin-left: auto;
  margin-right: auto; }
  @media (--mobile) {
    .c-i-inner {
      width: 100%;
      padding-left: 4rem;
      padding-right: 4rem; } }

.c-o-inner {
  width: 120rem;
  margin-left: auto;
  margin-right: auto; }
  @media (--mobile) {
    .c-o-inner {
      width: 100%;
      padding-left: 4rem;
      padding-right: 4rem; } }

/**
* wordpress
* WYSIWYG compatchble style
*/
.c-wp-post_article strong {
  font-weight: bold; }

.c-wp-post_article em {
  font-style: italic; }

.c-wp-post_article ul {
  margin-left: 1em;
  display: block;
  list-style-type: disc !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article ol {
  display: block;
  list-style-type: decimal !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px; }

.c-wp-post_article li {
  display: list-item;
  text-align: match-parent; }

.c-wp-post_article img {
  width: auto; }

.c-wp-post_article .alignleft {
  display: inline;
  float: left; }

.c-wp-post_article .alignright {
  display: inline;
  float: right; }

.c-wp-post_article .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-wp-post_article blockquote.alignleft,
.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article img.alignleft {
  margin: 0.4em 1.6em 1.6em 0; }

.c-wp-post_article blockquote.alignright,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article img.alignright {
  margin: 0.4em 0 1.6em 1.6em; }

.c-wp-post_article blockquote.aligncenter,
.c-wp-post_article .wp-caption.aligncenter,
.c-wp-post_article img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em; }

.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article .wp-caption.aligncenter {
  margin-bottom: 1.2em; }

/**
 * clearfix micro
 */
.clearfix:after {
  content: "";
  clear: both;
  display: block; }

/**
 * text ellipsis., text...
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u-hide {
  display: none; }

.u-click {
  cursor: pointer; }

@media (--large) {
  .for-mobile {
    display: none; } }

@media (--mobile) {
  .for-large {
    display: none; } }

/* ==========================================================================
 margin
========================================================================== */
.u-m-c {
  margin-right: auto !important;
  margin-left: auto !important; }

.u-m-r {
  margin-right: auto !important;
  margin-left: 0 !important; }

/* Top margin */
.u-mt-0 {
  margin-top: 0rem !important; }

.u-mt-1 {
  margin-top: 1rem !important; }

.u-mt-2 {
  margin-top: 2rem !important; }

.u-mt-3 {
  margin-top: 3rem !important; }

.u-mt-4 {
  margin-top: 4rem !important; }

.u-mt-5 {
  margin-top: 5rem !important; }

.u-mt-6 {
  margin-top: 6rem !important; }

.u-mt-7 {
  margin-top: 7rem !important; }

.u-mt-8 {
  margin-top: 8rem !important; }

.u-mt-9 {
  margin-top: 9rem !important; }

.u-mt-10 {
  margin-top: 10rem !important; }

.u-mt-11 {
  margin-top: 11rem !important; }

.u-mt-12 {
  margin-top: 12rem !important; }

.u-mt-13 {
  margin-top: 13rem !important; }

.u-mt-14 {
  margin-top: 14rem !important; }

.u-mt-15 {
  margin-top: 15rem !important; }

.u-mt-16 {
  margin-top: 16rem !important; }

.u-mt-17 {
  margin-top: 17rem !important; }

.u-mt-18 {
  margin-top: 18rem !important; }

.u-mt-19 {
  margin-top: 19rem !important; }

.u-mt-20 {
  margin-top: 20rem !important; }

.u-mt-21 {
  margin-top: 21rem !important; }

.u-mt-22 {
  margin-top: 22rem !important; }

.u-mt-23 {
  margin-top: 23rem !important; }

.u-mt-24 {
  margin-top: 24rem !important; }

.u-mt-25 {
  margin-top: 25rem !important; }

/* Right margin */
.u-mr-0 {
  margin-right: 0rem !important; }

.u-mr-1 {
  margin-right: 1rem !important; }

.u-mr-2 {
  margin-right: 2rem !important; }

.u-mr-3 {
  margin-right: 3rem !important; }

.u-mr-4 {
  margin-right: 4rem !important; }

.u-mr-5 {
  margin-right: 5rem !important; }

.u-mr-6 {
  margin-right: 6rem !important; }

.u-mr-7 {
  margin-right: 7rem !important; }

.u-mr-8 {
  margin-right: 8rem !important; }

.u-mr-9 {
  margin-right: 9rem !important; }

.u-mr-10 {
  margin-right: 10rem !important; }

.u-mr-11 {
  margin-right: 11rem !important; }

.u-mr-12 {
  margin-right: 12rem !important; }

.u-mr-13 {
  margin-right: 13rem !important; }

.u-mr-14 {
  margin-right: 14rem !important; }

.u-mr-15 {
  margin-right: 15rem !important; }

.u-mr-16 {
  margin-right: 16rem !important; }

.u-mr-17 {
  margin-right: 17rem !important; }

.u-mr-18 {
  margin-right: 18rem !important; }

.u-mr-19 {
  margin-right: 19rem !important; }

.u-mr-20 {
  margin-right: 20rem !important; }

.u-mr-21 {
  margin-right: 21rem !important; }

.u-mr-22 {
  margin-right: 22rem !important; }

.u-mr-23 {
  margin-right: 23rem !important; }

.u-mr-24 {
  margin-right: 24rem !important; }

.u-mr-25 {
  margin-right: 25rem !important; }

/* Bottom margin */
.u-mb-0 {
  margin-bottom: 0rem !important; }

.u-mb-1 {
  margin-bottom: 1rem !important; }

.u-mb-2 {
  margin-bottom: 2rem !important; }

.u-mb-3 {
  margin-bottom: 3rem !important; }

.u-mb-4 {
  margin-bottom: 4rem !important; }

.u-mb-5 {
  margin-bottom: 5rem !important; }

.u-mb-6 {
  margin-bottom: 6rem !important; }

.u-mb-7 {
  margin-bottom: 7rem !important; }

.u-mb-8 {
  margin-bottom: 8rem !important; }

.u-mb-9 {
  margin-bottom: 9rem !important; }

.u-mb-10 {
  margin-bottom: 10rem !important; }

.u-mb-11 {
  margin-bottom: 11rem !important; }

.u-mb-12 {
  margin-bottom: 12rem !important; }

.u-mb-13 {
  margin-bottom: 13rem !important; }

.u-mb-14 {
  margin-bottom: 14rem !important; }

.u-mb-15 {
  margin-bottom: 15rem !important; }

.u-mb-16 {
  margin-bottom: 16rem !important; }

.u-mb-17 {
  margin-bottom: 17rem !important; }

.u-mb-18 {
  margin-bottom: 18rem !important; }

.u-mb-19 {
  margin-bottom: 19rem !important; }

.u-mb-20 {
  margin-bottom: 20rem !important; }

.u-mb-21 {
  margin-bottom: 21rem !important; }

.u-mb-22 {
  margin-bottom: 22rem !important; }

.u-mb-23 {
  margin-bottom: 23rem !important; }

.u-mb-24 {
  margin-bottom: 24rem !important; }

.u-mb-25 {
  margin-bottom: 25rem !important; }

/* Left margin */
.u-ml-0 {
  margin-left: 0rem !important; }

.u-ml-1 {
  margin-left: 1rem !important; }

.u-ml-2 {
  margin-left: 2rem !important; }

.u-ml-3 {
  margin-left: 3rem !important; }

.u-ml-4 {
  margin-left: 4rem !important; }

.u-ml-5 {
  margin-left: 5rem !important; }

.u-ml-6 {
  margin-left: 6rem !important; }

.u-ml-7 {
  margin-left: 7rem !important; }

.u-ml-8 {
  margin-left: 8rem !important; }

.u-ml-9 {
  margin-left: 9rem !important; }

.u-ml-10 {
  margin-left: 10rem !important; }

.u-ml-11 {
  margin-left: 11rem !important; }

.u-ml-12 {
  margin-left: 12rem !important; }

.u-ml-13 {
  margin-left: 13rem !important; }

.u-ml-14 {
  margin-left: 14rem !important; }

.u-ml-15 {
  margin-left: 15rem !important; }

.u-ml-16 {
  margin-left: 16rem !important; }

.u-ml-17 {
  margin-left: 17rem !important; }

.u-ml-18 {
  margin-left: 18rem !important; }

.u-ml-19 {
  margin-left: 19rem !important; }

.u-ml-20 {
  margin-left: 20rem !important; }

.u-ml-21 {
  margin-left: 21rem !important; }

.u-ml-22 {
  margin-left: 22rem !important; }

.u-ml-23 {
  margin-left: 23rem !important; }

.u-ml-24 {
  margin-left: 24rem !important; }

.u-ml-25 {
  margin-left: 25rem !important; }

@media (--mobile) {
  .u-m-sp-c {
    margin-right: auto !important;
    margin-left: auto !important; }
  .u-m-sp-r {
    margin-right: auto !important;
    margin-left: 0 !important; }
  /* Top margin */
  .u-mt-sp-0 {
    margin-top: 0rem !important; }
  .u-mt-sp-1 {
    margin-top: 1rem !important; }
  .u-mt-sp-2 {
    margin-top: 2rem !important; }
  .u-mt-sp-3 {
    margin-top: 3rem !important; }
  .u-mt-sp-4 {
    margin-top: 4rem !important; }
  .u-mt-sp-5 {
    margin-top: 5rem !important; }
  .u-mt-sp-6 {
    margin-top: 6rem !important; }
  .u-mt-sp-7 {
    margin-top: 7rem !important; }
  .u-mt-sp-8 {
    margin-top: 8rem !important; }
  .u-mt-sp-9 {
    margin-top: 9rem !important; }
  .u-mt-sp-10 {
    margin-top: 10rem !important; }
  .u-mt-sp-11 {
    margin-top: 11rem !important; }
  .u-mt-sp-12 {
    margin-top: 12rem !important; }
  .u-mt-sp-13 {
    margin-top: 13rem !important; }
  .u-mt-sp-14 {
    margin-top: 14rem !important; }
  .u-mt-sp-15 {
    margin-top: 15rem !important; }
  .u-mt-sp-16 {
    margin-top: 16rem !important; }
  .u-mt-sp-17 {
    margin-top: 17rem !important; }
  .u-mt-sp-18 {
    margin-top: 18rem !important; }
  .u-mt-sp-19 {
    margin-top: 19rem !important; }
  .u-mt-sp-20 {
    margin-top: 20rem !important; }
  .u-mt-sp-21 {
    margin-top: 21rem !important; }
  .u-mt-sp-22 {
    margin-top: 22rem !important; }
  .u-mt-sp-23 {
    margin-top: 23rem !important; }
  .u-mt-sp-24 {
    margin-top: 24rem !important; }
  .u-mt-sp-25 {
    margin-top: 25rem !important; }
  /* Right margin */
  .u-mr-sp-0 {
    margin-right: 0rem !important; }
  .u-mr-sp-1 {
    margin-right: 1rem !important; }
  .u-mr-sp-2 {
    margin-right: 2rem !important; }
  .u-mr-sp-3 {
    margin-right: 3rem !important; }
  .u-mr-sp-4 {
    margin-right: 4rem !important; }
  .u-mr-sp-5 {
    margin-right: 5rem !important; }
  .u-mr-sp-6 {
    margin-right: 6rem !important; }
  .u-mr-sp-7 {
    margin-right: 7rem !important; }
  .u-mr-sp-8 {
    margin-right: 8rem !important; }
  .u-mr-sp-9 {
    margin-right: 9rem !important; }
  .u-mr-sp-10 {
    margin-right: 10rem !important; }
  .u-mr-sp-11 {
    margin-right: 11rem !important; }
  .u-mr-sp-12 {
    margin-right: 12rem !important; }
  .u-mr-sp-13 {
    margin-right: 13rem !important; }
  .u-mr-sp-14 {
    margin-right: 14rem !important; }
  .u-mr-sp-15 {
    margin-right: 15rem !important; }
  .u-mr-sp-16 {
    margin-right: 16rem !important; }
  .u-mr-sp-17 {
    margin-right: 17rem !important; }
  .u-mr-sp-18 {
    margin-right: 18rem !important; }
  .u-mr-sp-19 {
    margin-right: 19rem !important; }
  .u-mr-sp-20 {
    margin-right: 20rem !important; }
  .u-mr-sp-21 {
    margin-right: 21rem !important; }
  .u-mr-sp-22 {
    margin-right: 22rem !important; }
  .u-mr-sp-23 {
    margin-right: 23rem !important; }
  .u-mr-sp-24 {
    margin-right: 24rem !important; }
  .u-mr-sp-25 {
    margin-right: 25rem !important; }
  /* Bottom margin */
  .u-mb-sp-0 {
    margin-bottom: 0rem !important; }
  .u-mb-sp-1 {
    margin-bottom: 1rem !important; }
  .u-mb-sp-2 {
    margin-bottom: 2rem !important; }
  .u-mb-sp-3 {
    margin-bottom: 3rem !important; }
  .u-mb-sp-4 {
    margin-bottom: 4rem !important; }
  .u-mb-sp-5 {
    margin-bottom: 5rem !important; }
  .u-mb-sp-6 {
    margin-bottom: 6rem !important; }
  .u-mb-sp-7 {
    margin-bottom: 7rem !important; }
  .u-mb-sp-8 {
    margin-bottom: 8rem !important; }
  .u-mb-sp-9 {
    margin-bottom: 9rem !important; }
  .u-mb-sp-10 {
    margin-bottom: 10rem !important; }
  .u-mb-sp-11 {
    margin-bottom: 11rem !important; }
  .u-mb-sp-12 {
    margin-bottom: 12rem !important; }
  .u-mb-sp-13 {
    margin-bottom: 13rem !important; }
  .u-mb-sp-14 {
    margin-bottom: 14rem !important; }
  .u-mb-sp-15 {
    margin-bottom: 15rem !important; }
  .u-mb-sp-16 {
    margin-bottom: 16rem !important; }
  .u-mb-sp-17 {
    margin-bottom: 17rem !important; }
  .u-mb-sp-18 {
    margin-bottom: 18rem !important; }
  .u-mb-sp-19 {
    margin-bottom: 19rem !important; }
  .u-mb-sp-20 {
    margin-bottom: 20rem !important; }
  .u-mb-sp-21 {
    margin-bottom: 21rem !important; }
  .u-mb-sp-22 {
    margin-bottom: 22rem !important; }
  .u-mb-sp-23 {
    margin-bottom: 23rem !important; }
  .u-mb-sp-24 {
    margin-bottom: 24rem !important; }
  .u-mb-sp-25 {
    margin-bottom: 25rem !important; }
  /* Left margin */
  .u-ml-sp-0 {
    margin-left: 0rem !important; }
  .u-ml-sp-1 {
    margin-left: 1rem !important; }
  .u-ml-sp-2 {
    margin-left: 2rem !important; }
  .u-ml-sp-3 {
    margin-left: 3rem !important; }
  .u-ml-sp-4 {
    margin-left: 4rem !important; }
  .u-ml-sp-5 {
    margin-left: 5rem !important; }
  .u-ml-sp-6 {
    margin-left: 6rem !important; }
  .u-ml-sp-7 {
    margin-left: 7rem !important; }
  .u-ml-sp-8 {
    margin-left: 8rem !important; }
  .u-ml-sp-9 {
    margin-left: 9rem !important; }
  .u-ml-sp-10 {
    margin-left: 10rem !important; }
  .u-ml-sp-11 {
    margin-left: 11rem !important; }
  .u-ml-sp-12 {
    margin-left: 12rem !important; }
  .u-ml-sp-13 {
    margin-left: 13rem !important; }
  .u-ml-sp-14 {
    margin-left: 14rem !important; }
  .u-ml-sp-15 {
    margin-left: 15rem !important; }
  .u-ml-sp-16 {
    margin-left: 16rem !important; }
  .u-ml-sp-17 {
    margin-left: 17rem !important; }
  .u-ml-sp-18 {
    margin-left: 18rem !important; }
  .u-ml-sp-19 {
    margin-left: 19rem !important; }
  .u-ml-sp-20 {
    margin-left: 20rem !important; }
  .u-ml-sp-21 {
    margin-left: 21rem !important; }
  .u-ml-sp-22 {
    margin-left: 22rem !important; }
  .u-ml-sp-23 {
    margin-left: 23rem !important; }
  .u-ml-sp-24 {
    margin-left: 24rem !important; }
  .u-ml-sp-25 {
    margin-left: 25rem !important; } }

.u-objectFit {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; }

/* ==========================================================================
 padding
========================================================================== */
/* Top padding */
.u-pt-0 {
  padding-top: 0rem !important; }

.u-pt-1 {
  padding-top: 1rem !important; }

.u-pt-2 {
  padding-top: 2rem !important; }

.u-pt-3 {
  padding-top: 3rem !important; }

.u-pt-4 {
  padding-top: 4rem !important; }

.u-pt-5 {
  padding-top: 5rem !important; }

.u-pt-6 {
  padding-top: 6rem !important; }

.u-pt-7 {
  padding-top: 7rem !important; }

.u-pt-8 {
  padding-top: 8rem !important; }

.u-pt-9 {
  padding-top: 9rem !important; }

.u-pt-10 {
  padding-top: 10rem !important; }

.u-pt-11 {
  padding-top: 11rem !important; }

.u-pt-12 {
  padding-top: 12rem !important; }

.u-pt-13 {
  padding-top: 13rem !important; }

.u-pt-14 {
  padding-top: 14rem !important; }

.u-pt-15 {
  padding-top: 15rem !important; }

.u-pt-16 {
  padding-top: 16rem !important; }

.u-pt-17 {
  padding-top: 17rem !important; }

.u-pt-18 {
  padding-top: 18rem !important; }

.u-pt-19 {
  padding-top: 19rem !important; }

.u-pt-20 {
  padding-top: 20rem !important; }

.u-pt-21 {
  padding-top: 21rem !important; }

.u-pt-22 {
  padding-top: 22rem !important; }

.u-pt-23 {
  padding-top: 23rem !important; }

.u-pt-24 {
  padding-top: 24rem !important; }

.u-pt-25 {
  padding-top: 25rem !important; }

/* Right padding */
.u-pr-0 {
  padding-right: 0rem !important; }

.u-pr-1 {
  padding-right: 1rem !important; }

.u-pr-2 {
  padding-right: 2rem !important; }

.u-pr-3 {
  padding-right: 3rem !important; }

.u-pr-4 {
  padding-right: 4rem !important; }

.u-pr-5 {
  padding-right: 5rem !important; }

.u-pr-6 {
  padding-right: 6rem !important; }

.u-pr-7 {
  padding-right: 7rem !important; }

.u-pr-8 {
  padding-right: 8rem !important; }

.u-pr-9 {
  padding-right: 9rem !important; }

.u-pr-10 {
  padding-right: 10rem !important; }

.u-pr-11 {
  padding-right: 11rem !important; }

.u-pr-12 {
  padding-right: 12rem !important; }

.u-pr-13 {
  padding-right: 13rem !important; }

.u-pr-14 {
  padding-right: 14rem !important; }

.u-pr-15 {
  padding-right: 15rem !important; }

.u-pr-16 {
  padding-right: 16rem !important; }

.u-pr-17 {
  padding-right: 17rem !important; }

.u-pr-18 {
  padding-right: 18rem !important; }

.u-pr-19 {
  padding-right: 19rem !important; }

.u-pr-20 {
  padding-right: 20rem !important; }

.u-pr-21 {
  padding-right: 21rem !important; }

.u-pr-22 {
  padding-right: 22rem !important; }

.u-pr-23 {
  padding-right: 23rem !important; }

.u-pr-24 {
  padding-right: 24rem !important; }

.u-pr-25 {
  padding-right: 25rem !important; }

/* Bottom padding */
.u-pb-0 {
  padding-bottom: 0rem !important; }

.u-pb-1 {
  padding-bottom: 1rem !important; }

.u-pb-2 {
  padding-bottom: 2rem !important; }

.u-pb-3 {
  padding-bottom: 3rem !important; }

.u-pb-4 {
  padding-bottom: 4rem !important; }

.u-pb-5 {
  padding-bottom: 5rem !important; }

.u-pb-6 {
  padding-bottom: 6rem !important; }

.u-pb-7 {
  padding-bottom: 7rem !important; }

.u-pb-8 {
  padding-bottom: 8rem !important; }

.u-pb-9 {
  padding-bottom: 9rem !important; }

.u-pb-10 {
  padding-bottom: 10rem !important; }

.u-pb-11 {
  padding-bottom: 11rem !important; }

.u-pb-12 {
  padding-bottom: 12rem !important; }

.u-pb-13 {
  padding-bottom: 13rem !important; }

.u-pb-14 {
  padding-bottom: 14rem !important; }

.u-pb-15 {
  padding-bottom: 15rem !important; }

.u-pb-16 {
  padding-bottom: 16rem !important; }

.u-pb-17 {
  padding-bottom: 17rem !important; }

.u-pb-18 {
  padding-bottom: 18rem !important; }

.u-pb-19 {
  padding-bottom: 19rem !important; }

.u-pb-20 {
  padding-bottom: 20rem !important; }

.u-pb-21 {
  padding-bottom: 21rem !important; }

.u-pb-22 {
  padding-bottom: 22rem !important; }

.u-pb-23 {
  padding-bottom: 23rem !important; }

.u-pb-24 {
  padding-bottom: 24rem !important; }

.u-pb-25 {
  padding-bottom: 25rem !important; }

/* Left padding */
.u-pl-0 {
  padding-left: 0rem !important; }

.u-pl-1 {
  padding-left: 1rem !important; }

.u-pl-2 {
  padding-left: 2rem !important; }

.u-pl-3 {
  padding-left: 3rem !important; }

.u-pl-4 {
  padding-left: 4rem !important; }

.u-pl-5 {
  padding-left: 5rem !important; }

.u-pl-6 {
  padding-left: 6rem !important; }

.u-pl-7 {
  padding-left: 7rem !important; }

.u-pl-8 {
  padding-left: 8rem !important; }

.u-pl-9 {
  padding-left: 9rem !important; }

.u-pl-10 {
  padding-left: 10rem !important; }

.u-pl-11 {
  padding-left: 11rem !important; }

.u-pl-12 {
  padding-left: 12rem !important; }

.u-pl-13 {
  padding-left: 13rem !important; }

.u-pl-14 {
  padding-left: 14rem !important; }

.u-pl-15 {
  padding-left: 15rem !important; }

.u-pl-16 {
  padding-left: 16rem !important; }

.u-pl-17 {
  padding-left: 17rem !important; }

.u-pl-18 {
  padding-left: 18rem !important; }

.u-pl-19 {
  padding-left: 19rem !important; }

.u-pl-20 {
  padding-left: 20rem !important; }

.u-pl-21 {
  padding-left: 21rem !important; }

.u-pl-22 {
  padding-left: 22rem !important; }

.u-pl-23 {
  padding-left: 23rem !important; }

.u-pl-24 {
  padding-left: 24rem !important; }

.u-pl-25 {
  padding-left: 25rem !important; }

@media (--mobile) {
  /* Top padding */
  .u-pt-sp-0 {
    padding-top: 0rem !important; }
  .u-pt-sp-1 {
    padding-top: 1rem !important; }
  .u-pt-sp-2 {
    padding-top: 2rem !important; }
  .u-pt-sp-3 {
    padding-top: 3rem !important; }
  .u-pt-sp-4 {
    padding-top: 4rem !important; }
  .u-pt-sp-5 {
    padding-top: 5rem !important; }
  .u-pt-sp-6 {
    padding-top: 6rem !important; }
  .u-pt-sp-7 {
    padding-top: 7rem !important; }
  .u-pt-sp-8 {
    padding-top: 8rem !important; }
  .u-pt-sp-9 {
    padding-top: 9rem !important; }
  .u-pt-sp-10 {
    padding-top: 10rem !important; }
  .u-pt-sp-11 {
    padding-top: 11rem !important; }
  .u-pt-sp-12 {
    padding-top: 12rem !important; }
  .u-pt-sp-13 {
    padding-top: 13rem !important; }
  .u-pt-sp-14 {
    padding-top: 14rem !important; }
  .u-pt-sp-15 {
    padding-top: 15rem !important; }
  .u-pt-sp-16 {
    padding-top: 16rem !important; }
  .u-pt-sp-17 {
    padding-top: 17rem !important; }
  .u-pt-sp-18 {
    padding-top: 18rem !important; }
  .u-pt-sp-19 {
    padding-top: 19rem !important; }
  .u-pt-sp-20 {
    padding-top: 20rem !important; }
  .u-pt-sp-21 {
    padding-top: 21rem !important; }
  .u-pt-sp-22 {
    padding-top: 22rem !important; }
  .u-pt-sp-23 {
    padding-top: 23rem !important; }
  .u-pt-sp-24 {
    padding-top: 24rem !important; }
  .u-pt-sp-25 {
    padding-top: 25rem !important; }
  /* Right padding */
  .u-pr-sp-0 {
    padding-right: 0rem !important; }
  .u-pr-sp-1 {
    padding-right: 1rem !important; }
  .u-pr-sp-2 {
    padding-right: 2rem !important; }
  .u-pr-sp-3 {
    padding-right: 3rem !important; }
  .u-pr-sp-4 {
    padding-right: 4rem !important; }
  .u-pr-sp-5 {
    padding-right: 5rem !important; }
  .u-pr-sp-6 {
    padding-right: 6rem !important; }
  .u-pr-sp-7 {
    padding-right: 7rem !important; }
  .u-pr-sp-8 {
    padding-right: 8rem !important; }
  .u-pr-sp-9 {
    padding-right: 9rem !important; }
  .u-pr-sp-10 {
    padding-right: 10rem !important; }
  .u-pr-sp-11 {
    padding-right: 11rem !important; }
  .u-pr-sp-12 {
    padding-right: 12rem !important; }
  .u-pr-sp-13 {
    padding-right: 13rem !important; }
  .u-pr-sp-14 {
    padding-right: 14rem !important; }
  .u-pr-sp-15 {
    padding-right: 15rem !important; }
  .u-pr-sp-16 {
    padding-right: 16rem !important; }
  .u-pr-sp-17 {
    padding-right: 17rem !important; }
  .u-pr-sp-18 {
    padding-right: 18rem !important; }
  .u-pr-sp-19 {
    padding-right: 19rem !important; }
  .u-pr-sp-20 {
    padding-right: 20rem !important; }
  .u-pr-sp-21 {
    padding-right: 21rem !important; }
  .u-pr-sp-22 {
    padding-right: 22rem !important; }
  .u-pr-sp-23 {
    padding-right: 23rem !important; }
  .u-pr-sp-24 {
    padding-right: 24rem !important; }
  .u-pr-sp-25 {
    padding-right: 25rem !important; }
  /* Bottom padding */
  .u-pb-sp-0 {
    padding-bottom: 0rem !important; }
  .u-pb-sp-1 {
    padding-bottom: 1rem !important; }
  .u-pb-sp-2 {
    padding-bottom: 2rem !important; }
  .u-pb-sp-3 {
    padding-bottom: 3rem !important; }
  .u-pb-sp-4 {
    padding-bottom: 4rem !important; }
  .u-pb-sp-5 {
    padding-bottom: 5rem !important; }
  .u-pb-sp-6 {
    padding-bottom: 6rem !important; }
  .u-pb-sp-7 {
    padding-bottom: 7rem !important; }
  .u-pb-sp-8 {
    padding-bottom: 8rem !important; }
  .u-pb-sp-9 {
    padding-bottom: 9rem !important; }
  .u-pb-sp-10 {
    padding-bottom: 10rem !important; }
  .u-pb-sp-11 {
    padding-bottom: 11rem !important; }
  .u-pb-sp-12 {
    padding-bottom: 12rem !important; }
  .u-pb-sp-13 {
    padding-bottom: 13rem !important; }
  .u-pb-sp-14 {
    padding-bottom: 14rem !important; }
  .u-pb-sp-15 {
    padding-bottom: 15rem !important; }
  .u-pb-sp-16 {
    padding-bottom: 16rem !important; }
  .u-pb-sp-17 {
    padding-bottom: 17rem !important; }
  .u-pb-sp-18 {
    padding-bottom: 18rem !important; }
  .u-pb-sp-19 {
    padding-bottom: 19rem !important; }
  .u-pb-sp-20 {
    padding-bottom: 20rem !important; }
  .u-pb-sp-21 {
    padding-bottom: 21rem !important; }
  .u-pb-sp-22 {
    padding-bottom: 22rem !important; }
  .u-pb-sp-23 {
    padding-bottom: 23rem !important; }
  .u-pb-sp-24 {
    padding-bottom: 24rem !important; }
  .u-pb-sp-25 {
    padding-bottom: 25rem !important; }
  /* Left padding */
  .u-pl-sp-0 {
    padding-left: 0rem !important; }
  .u-pl-sp-1 {
    padding-left: 1rem !important; }
  .u-pl-sp-2 {
    padding-left: 2rem !important; }
  .u-pl-sp-3 {
    padding-left: 3rem !important; }
  .u-pl-sp-4 {
    padding-left: 4rem !important; }
  .u-pl-sp-5 {
    padding-left: 5rem !important; }
  .u-pl-sp-6 {
    padding-left: 6rem !important; }
  .u-pl-sp-7 {
    padding-left: 7rem !important; }
  .u-pl-sp-8 {
    padding-left: 8rem !important; }
  .u-pl-sp-9 {
    padding-left: 9rem !important; }
  .u-pl-sp-10 {
    padding-left: 10rem !important; }
  .u-pl-sp-11 {
    padding-left: 11rem !important; }
  .u-pl-sp-12 {
    padding-left: 12rem !important; }
  .u-pl-sp-13 {
    padding-left: 13rem !important; }
  .u-pl-sp-14 {
    padding-left: 14rem !important; }
  .u-pl-sp-15 {
    padding-left: 15rem !important; }
  .u-pl-sp-16 {
    padding-left: 16rem !important; }
  .u-pl-sp-17 {
    padding-left: 17rem !important; }
  .u-pl-sp-18 {
    padding-left: 18rem !important; }
  .u-pl-sp-19 {
    padding-left: 19rem !important; }
  .u-pl-sp-20 {
    padding-left: 20rem !important; }
  .u-pl-sp-21 {
    padding-left: 21rem !important; }
  .u-pl-sp-22 {
    padding-left: 22rem !important; }
  .u-pl-sp-23 {
    padding-left: 23rem !important; }
  .u-pl-sp-24 {
    padding-left: 24rem !important; }
  .u-pl-sp-25 {
    padding-left: 25rem !important; } }

.js-parallax {
  overflow: hidden; }
  .js-parallax img {
    transform: scale(1.1) translateY(20px); }
  .js-parallax--reverse img {
    transform: scale(1.1) translateY(-20px); }

/**
 * BrowserSync
 */
#__bs_notify__ {
  display: none !important;
  font-size: 8px !important;
  opacity: .25;
  max-height: 50px; }

.__debug_mihon__ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto 0 auto;
  z-index: 100000;
  pointer-events: none;
  cursor: none;
  width: 100%;
  overflow: hidden;
  text-align: center;
  animation: __debug_mihon__ 1s infinite; }

.__debug_mihon__ {
  padding-top: 0px;
  margin-top: 0px; }

@-webkit-keyframes __debug_mihon__ {
  0% {
    opacity: 0; }
  60% {
    opacity: .5; }
  100% {
    opacity: .5; } }

/*
@example
 <div class="mihon">
  <img src="./images/sample1.png" alt="" class="type_pc">
  <img src="./images/sample2.png" alt="" class="type_sp">
</div>
*/
.p-commitment {
  padding-top: 0 !important; }
  .p-commitment__video {
    width: 100%;
    max-height: 86rem;
    height: 100vh; }
    @media (--mobile) {
      .p-commitment__video {
        height: 30rem; } }
    .p-commitment__video video {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .p-commitment__mv {
    width: 100%;
    max-height: 86rem;
    height: 100vh; }
    @media (--mobile) {
      .p-commitment__mv {
        height: 30rem; } }
  .p-commitment__heading {
    margin-top: 10rem; }
    @media (--mobile) {
      .p-commitment__heading {
        margin-top: 6rem; } }
    .p-commitment__heading .heading-inner {
      position: relative;
      width: 65rem;
      margin-right: auto;
      margin-left: auto; }
      @media (--mobile) {
        .p-commitment__heading .heading-inner {
          width: 100%; } }
    .p-commitment__heading .heading-text {
      margin-top: 10.6rem; }
      @media (--mobile) {
        .p-commitment__heading .heading-text {
          margin-top: 6.2rem; } }
      .p-commitment__heading .heading-text p:nth-child(n + 2) {
        margin-top: 4.6rem; }
        @media (--mobile) {
          .p-commitment__heading .heading-text p:nth-child(n + 2) {
            margin-top: 2.8rem; } }
    .p-commitment__heading .heading-message {
      display: flex;
      align-items: center;
      margin-top: 8.2rem; }
      @media (--mobile) {
        .p-commitment__heading .heading-message {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 2em; } }
      .p-commitment__heading .heading-message .message-logo svg {
        width: 14.2rem;
        height: 7.4rem; }
        @media (--mobile) {
          .p-commitment__heading .heading-message .message-logo svg {
            width: calc(14.2rem * 0.7);
            height: calc(7.4rem * 0.7); } }
        .p-commitment__heading .heading-message .message-logo svg path {
          fill: #430E12; }
      .p-commitment__heading .heading-message .message-text {
        margin-left: 5rem; }
        @media (--mobile) {
          .p-commitment__heading .heading-message .message-text {
            margin-top: 0.5rem;
            margin-left: 0; } }
    .p-commitment__heading .heading-video {
      margin-top: 15.2rem; }
      @media (--mobile) {
        .p-commitment__heading .heading-video {
          margin-top: 4rem; } }
  .p-commitment__contents .contents-concept {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15.6rem; }
    @media (--mobile) {
      .p-commitment__contents .contents-concept {
        flex-direction: column;
        margin-top: 8rem; } }
    .p-commitment__contents .contents-concept__left {
      width: 43rem; }
      @media (--mobile) {
        .p-commitment__contents .contents-concept__left {
          width: 100%; } }
      .p-commitment__contents .contents-concept__left h2 {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 2.1rem; }
        .p-commitment__contents .contents-concept__left h2::before {
          position: absolute;
          top: 50%;
          left: -4.5rem;
          width: 3.3rem;
          height: 0.1rem;
          content: "";
          background-color: #dbdbdb;
          transform: translateY(-50%);
          transform: translateY(-50%); }
        .p-commitment__contents .contents-concept__left h2 small {
          margin-left: 2rem;
          font-size: 1.2rem; }
      .p-commitment__contents .contents-concept__left p {
        margin-top: 5.3rem; }
        @media (--mobile) {
          .p-commitment__contents .contents-concept__left p {
            margin-top: 3.2rem; } }
        .p-commitment__contents .contents-concept__left p:nth-of-type(n + 2) {
          margin-top: 1.5rem; }
    .p-commitment__contents .contents-concept__right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52rem;
      height: 34.7rem;
      background-color: white; }
      @media (--mobile) {
        .p-commitment__contents .contents-concept__right {
          margin-top: 2.4rem;
          width: 100%; } }
      .p-commitment__contents .contents-concept__right svg {
        width: 18.6rem;
        height: 24.3em; }
        .p-commitment__contents .contents-concept__right svg path {
          fill: #430E12; }

.p-menu__heading .heading-img {
  width: calc(1300 / 1400 * 100%);
  margin-top: 8.2rem; }
  @media (--mobile) {
    .p-menu__heading .heading-img {
      width: 100%;
      height: 24rem;
      margin-top: 4.2rem; } }
  .p-menu__heading .heading-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.p-menu__container {
  margin-top: 10.5rem; }
  @media (--mobile) {
    .p-menu__container {
      margin-top: 8rem; } }
  .p-menu__container .menu {
    padding-bottom: 5rem; }
    @media (--mobile) {
      .p-menu__container .menu {
        padding-bottom: 0; } }
    .p-menu__container .menu-course:nth-child(n + 2) {
      margin-top: 15.6rem; }
      @media (--mobile) {
        .p-menu__container .menu-course:nth-child(n + 2) {
          margin-top: 7rem; } }
    .p-menu__container .menu-course h2 {
      position: relative;
      display: flex;
      align-items: center; }
      .p-menu__container .menu-course h2 span {
        font-size: 2.1rem; }
      .p-menu__container .menu-course h2 small {
        margin-left: 2rem;
        font-size: 1.2rem; }
      .p-menu__container .menu-course h2::before {
        position: absolute;
        top: 50%;
        left: -4.5rem;
        width: 3.3rem;
        height: 0.1rem;
        content: "";
        background-color: #dbdbdb;
        transform: translateY(-50%); }
    .p-menu__container .menu-course.season .menu-content {
      margin-top: 5.8rem; }
      @media (--mobile) {
        .p-menu__container .menu-course.season .menu-content {
          margin-top: 4.2rem; } }
      .p-menu__container .menu-course.season .menu-content.puffer {
        margin-top: 9rem; }
        @media (--mobile) {
          .p-menu__container .menu-course.season .menu-content.puffer {
            margin-top: 5rem; } }
      .p-menu__container .menu-course.season .menu-content.select {
        padding: 5rem 5.5rem;
        background-color: rgba(255, 255, 255, 0.6); }
        @media (--mobile) {
          .p-menu__container .menu-course.season .menu-content.select {
            padding: 2.5rem; } }
      .p-menu__container .menu-course.season .menu-content h3 {
        display: flex;
        align-items: center;
        letter-spacing: 0.2em;
        border-bottom: 1px solid #DBDBDB;
        padding-bottom: 1.3rem; }
        .p-menu__container .menu-course.season .menu-content h3 span {
          font-size: 1.8rem; }
        .p-menu__container .menu-course.season .menu-content h3 small {
          font-size: 1.4rem;
          margin-left: 1rem; }
      .p-menu__container .menu-course.season .menu-content__text {
        margin-top: 4rem;
        font-size: 1.4rem; }
      .p-menu__container .menu-course.season .menu-content .content-puffer {
        padding: 5rem 5.5rem;
        background-color: rgba(255, 255, 255, 0.6);
        width: 52rem;
        margin-top: 4rem; }
        @media (--mobile) {
          .p-menu__container .menu-course.season .menu-content .content-puffer {
            padding: 2.5rem;
            width: 100%;
            margin-top: 2.4rem; } }
        .p-menu__container .menu-course.season .menu-content .content-puffer__ttl {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .p-menu__container .menu-course.season .menu-content .content-puffer__ttl h4 {
            font-size: 1.8rem; }
        .p-menu__container .menu-course.season .menu-content .content-puffer__corse {
          margin-top: 2rem; }
        .p-menu__container .menu-course.season .menu-content .content-puffer__text {
          margin-top: 2.4rem; }
          .p-menu__container .menu-course.season .menu-content .content-puffer__text p {
            line-height: 1.8; }
    .p-menu__container .menu-course.season .menu-list {
      width: 100%;
      margin-top: 4rem;
      flex-direction: row; }
      @media (--mobile) {
        .p-menu__container .menu-course.season .menu-list {
          margin-top: 2.4rem; } }
    .p-menu__container .menu-course.season .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 23rem;
      height: 23rem;
      background-color: rgba(255, 255, 255, 0.6); }
      .p-menu__container .menu-course.season .menu-item:nth-child(n + 2) {
        margin-top: 0; }
      @media (--mobile) {
        .p-menu__container .menu-course.season .menu-item {
          width: 14rem;
          height: 14rem;
          padding: 2rem; }
          .p-menu__container .menu-course.season .menu-item:nth-child(n + 3) {
            margin-top: 2rem; } }
      @media (--large) {
        .p-menu__container .menu-course.season .menu-item:nth-child(n + 3) {
          margin-top: 0; } }
      .p-menu__container .menu-course.season .menu-item__price {
        margin-top: 0.8rem;
        text-align: center; }
        @media (--mobile) {
          .p-menu__container .menu-course.season .menu-item__price {
            text-align: right; } }
    .p-menu__container .menu-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 5.3rem; }
      @media (--mobile) {
        .p-menu__container .menu-list {
          flex-direction: column;
          width: 100%;
          margin-top: 3.4rem; } }
    .p-menu__container .menu-item {
      width: 52rem;
      height: auto;
      background-color: rgba(255, 255, 255, 0.6);
      padding: 5rem 3.5rem 5rem 5rem; }
      @media (--mobile) {
        .p-menu__container .menu-item {
          padding: 2.5rem 2.5rem;
          height: auto; } }
      .p-menu__container .menu-item.no-white {
        background-color: transparent; }
      .p-menu__container .menu-item.no-space {
        padding-left: 0;
        padding-right: 0; }
        @media (--mobile) {
          .p-menu__container .menu-item.no-space {
            padding-bottom: 0;
            margin-top: 0 !important; } }
      @media (--large) {
        .p-menu__container .menu-item:nth-child(n + 3) {
          margin-top: 5rem; } }
      @media (--mobile) {
        .p-menu__container .menu-item {
          width: 100%; }
          .p-menu__container .menu-item:nth-child(n + 2) {
            margin-top: 4.2rem; } }
    @media (--mobile) and (--mobile) {
      .p-menu__container .menu-item:nth-child(n + 2) {
        margin-top: 3rem; } }
      .p-menu__container .menu-item__ttl {
        font-size: 1.8rem; }
        .p-menu__container .menu-item__ttl.main {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .p-menu__container .menu-item__ttl.main span.price {
            font-size: 1.5rem;
            margin-right: 4.5rem; }
        @media (--mobile) {
          .p-menu__container .menu-item__ttl {
            font-size: 1.7rem; } }
      .p-menu__container .menu-item__text {
        margin-top: 3.3rem;
        font-size: 1.5rem; }
        @media (--mobile) {
          .p-menu__container .menu-item__text {
            margin-top: 2rem; } }
        .p-menu__container .menu-item__text.m-0 {
          margin-top: 0; }
          @media (--mobile) {
            .p-menu__container .menu-item__text.m-0 {
              margin-top: 0; } }
        .p-menu__container .menu-item__text > span {
          display: block;
          font-size: 1.4rem; }
          .p-menu__container .menu-item__text > span:nth-child(n + 2) {
            margin-top: 1.5rem; }
            @media (--mobile) {
              .p-menu__container .menu-item__text > span:nth-child(n + 2) {
                margin-top: 1.2rem; } }
      .p-menu__container .menu-item__price {
        margin-top: 6rem;
        text-align: right; }
        @media (--mobile) {
          .p-menu__container .menu-item__price {
            margin-top: 0.8rem; } }

.p-news__heading {
  position: relative; }

.p-news__list {
  margin-top: 7.2rem; }
  @media (--mobile) {
    .p-news__list {
      margin-top: 3.8rem; } }

.p-news .wp-pagenavi {
  margin-top: 6rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 32rem;
  margin-left: auto;
  margin-right: auto; }
  @media (--mobile) {
    .p-news .wp-pagenavi {
      width: 24rem; } }
  .p-news .wp-pagenavi .page {
    font-size: 1.4rem; }
  .p-news .wp-pagenavi .first,
  .p-news .wp-pagenavi .last,
  .p-news .wp-pagenavi .page {
    padding: 1rem;
    transition: 0.3s;
    border: 1px solid transparent; }
    @media (--large) {
      .p-news .wp-pagenavi .first:hover,
      .p-news .wp-pagenavi .last:hover,
      .p-news .wp-pagenavi .page:hover {
        border: 1px solid #DBDBDB; } }
  .p-news .wp-pagenavi .current {
    border: 1px solid #DBDBDB;
    padding: 1rem;
    font-size: 1.6rem; }
  .p-news .wp-pagenavi .previouspostslink,
  .p-news .wp-pagenavi .nextpostslink {
    width: 2.8rem;
    height: 0.4rem;
    background-repeat: no-repeat;
    background-size: contain; }
  .p-news .wp-pagenavi .previouspostslink {
    background-image: url(../images/news/news-arrow-prev.svg);
    order: -10; }
  .p-news .wp-pagenavi .nextpostslink {
    background-image: url(../images/news/news-arrow-next.svg);
    order: 10; }

.c-page {
  padding-top: 18.3rem;
  padding-bottom: 20rem;
  letter-spacing: 0.2em;
  overflow: hidden; }
  @media (--mobile) {
    .c-page {
      padding-top: 12rem;
      padding-bottom: 10rem; } }
  .c-page__ttl {
    font-size: 2.6rem;
    text-align: center; }
    .c-page__ttl small {
      display: block;
      margin-top: 1.8rem;
      font-size: 1.4rem; }
  .c-page-ren {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    width: 44.6rem;
    height: 48.8rem;
    transform: translate(-50%, -50%) translateX(29rem) translateY(-12rem); }
    @media (--mobile) {
      .c-page-ren {
        width: calc(44.6rem * 0.8);
        height: calc(48.8rem * 0.8);
        transform: translate(-50%, -50%) translateX(10rem) translateY(-20rem); } }
    .c-page-ren.store-ren {
      top: 0;
      transform: translateY(-31rem); }
      @media (--mobile) {
        .c-page-ren.store-ren {
          width: calc(44.6rem * 0.8);
          height: calc(48.8rem * 0.8);
          transform: translate(-50%, -50%) translateX(10rem) translateY(-63rem); } }
    .c-page-ren.news-ren {
      top: 0;
      transform: translateY(-17.3rem); }
      @media (--mobile) {
        .c-page-ren.news-ren {
          width: calc(44.6rem * 0.8);
          height: calc(48.8rem * 0.8);
          transform: translate(-50%, -50%) translateX(7rem) translateY(2rem); } }

.c-page_contentList .contents-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 56.3rem;
  margin-top: 10.8rem; }
  .c-page_contentList .contents-item.mt-lg {
    margin-top: 20.2rem; }
    @media (--mobile) {
      .c-page_contentList .contents-item.mt-lg {
        margin-top: 8rem; } }
  @media (--mobile) {
    .c-page_contentList .contents-item {
      flex-direction: column-reverse;
      height: auto; } }
  @media (--mobile) {
    .c-page_contentList .contents-item {
      width: 100%; } }
  .c-page_contentList .contents-item:nth-child(n + 2) {
    margin-top: 15rem; }
    @media (--mobile) {
      .c-page_contentList .contents-item:nth-child(n + 2) {
        margin-top: 6.2rem; } }
  .c-page_contentList .contents-item__text .text-inner {
    width: 43rem; }
    @media (--mobile) {
      .c-page_contentList .contents-item__text .text-inner {
        width: 100%;
        margin-top: 3rem; } }
  .c-page_contentList .contents-item__text .text-ttl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1.7; }
    .c-page_contentList .contents-item__text .text-ttl.f-sm span {
      font-size: 2.8rem; }
      @media (--mobile) {
        .c-page_contentList .contents-item__text .text-ttl.f-sm span {
          font-size: 2.3rem; } }
    .c-page_contentList .contents-item__text .text-ttl span {
      font-size: 3.2rem; }
      @media (--mobile) {
        .c-page_contentList .contents-item__text .text-ttl span {
          font-size: 2.7rem; } }
      .c-page_contentList .contents-item__text .text-ttl span.position {
        display: block;
        width: 100%;
        margin-bottom: 0.4rem;
        font-size: 1.4rem; }
        @media (--mobile) {
          .c-page_contentList .contents-item__text .text-ttl span.position {
            margin-bottom: 0.8rem; } }
    .c-page_contentList .contents-item__text .text-ttl small {
      margin-left: 2rem;
      font-size: 1.2rem; }
  .c-page_contentList .contents-item__text .text-content {
    margin-top: 5.5rem; }
    @media (--mobile) {
      .c-page_contentList .contents-item__text .text-content {
        margin-top: 2.4rem; } }
    .c-page_contentList .contents-item__text .text-content p:nth-child(n + 2) {
      margin-top: 1.5rem; }
  .c-page_contentList .contents-item__text .text-career {
    margin-top: 4.8rem; }
    @media (--mobile) {
      .c-page_contentList .contents-item__text .text-career {
        margin-top: 2.4rem; } }
    .c-page_contentList .contents-item__text .text-career p {
      margin-bottom: 2rem;
      font-size: 1.2rem;
      color: #a5a5a5; }
    .c-page_contentList .contents-item__text .text-career ul li {
      margin-top: 1.4rem; }
  .c-page_contentList .contents-item__img {
    position: absolute;
    top: 0;
    right: 0;
    width: 67.9rem;
    height: 56.3rem; }
    @media (--mobile) {
      .c-page_contentList .contents-item__img {
        position: relative;
        width: 100%;
        height: 24rem; } }
    .c-page_contentList .contents-item__img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .c-page_contentList .contents-item.rev .text-inner {
    margin-right: 0;
    margin-left: auto; }
  .c-page_contentList .contents-item.rev .contents-item__img {
    right: initial;
    left: 0; }

.c-page__info {
  margin-top: 7rem;
  margin-bottom: 6.3rem; }
  .c-page__info.p-top__store dl {
    margin-bottom: 3.3rem; }
  @media (--mobile) {
    .c-page__info.store-info {
      margin-top: 0; } }
  .c-page__info dl {
    letter-spacing: 0.1em;
    padding: 3rem 0;
    display: flex;
    border-bottom: 0.1rem solid #DBDBDB; }
    .c-page__info dl.recruit dd span {
      display: block; }
      .c-page__info dl.recruit dd span:nth-child(n + 2) {
        margin-top: 1.2rem; }
    @media (--mobile) {
      .c-page__info dl {
        flex-direction: column;
        align-items: start; } }
    .c-page__info dl dt {
      width: 18rem;
      color: #A5A5A5; }
    .c-page__info dl dd {
      flex: 1; }
      @media (--mobile) {
        .c-page__info dl dd {
          line-height: 2;
          margin-top: 1rem; } }
      .c-page__info dl dd.open > span {
        display: block; }
        .c-page__info dl dd.open > span:nth-child(n + 2) {
          margin-top: 1.2rem; }
      .c-page__info dl dd.open-time {
        width: 15.5rem; }
      .c-page__info dl dd a {
        margin-top: 2rem;
        width: 14.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #DBDBDB; }
        .c-page__info dl dd a.tel {
          border-bottom: 0px;
          margin-top: 0;
          width: 100%;
          display: block; }
        @media (--mobile) {
          .c-page__info dl dd a {
            width: 14rem; } }
        .c-page__info dl dd a p {
          font-size: 1.4rem; }
        .c-page__info dl dd a svg {
          margin-left: 0.8rem;
          width: 1.4rem;
          height: 1.3rem; }

.c-page-news .list {
  width: 85.5rem;
  margin-left: auto;
  margin-right: auto; }
  @media (--mobile) {
    .c-page-news .list {
      width: 100%; } }
  .c-page-news .list-item {
    padding-top: 3rem;
    padding-bottom: 3.6rem;
    border-bottom: 1px solid #DBDBDB;
    transition: 0.3s; }
    @media (--large) {
      .c-page-news .list-item:hover {
        border-bottom: 1px solid #58574C; } }
    @media (--mobile) {
      .c-page-news .list-item {
        padding-top: 2.4rem;
        padding-bottom: 2.8rem; } }
    .c-page-news .list-item__ttl {
      display: flex; }
      @media (--mobile) {
        .c-page-news .list-item__ttl {
          flex-direction: column; } }
      .c-page-news .list-item__ttl time {
        margin-top: 1.2rem;
        width: 10.5rem;
        font-size: 1.2rem;
        color: #A5A5A5; }
      .c-page-news .list-item__ttl p {
        flex: 1;
        padding-right: 11rem;
        position: relative; }
        @media (--mobile) {
          .c-page-news .list-item__ttl p {
            margin-top: 1.2rem; } }
        .c-page-news .list-item__ttl p svg {
          position: absolute;
          width: 1.6rem;
          height: 0.8rem;
          top: 1.7rem;
          right: 2rem; }
    .c-page-news .list-item__description {
      margin-top: 1.6rem;
      display: none; }
      @media (--mobile) {
        .c-page-news .list-item__description {
          margin-top: 0rem; } }
      .c-page-news .list-item__description p {
        margin-left: 10.3rem;
        padding-right: 11rem;
        font-size: 1.4rem; }
        .c-page-news .list-item__description p:nth-child(n + 2) {
          padding-top: 2rem; }
          @media (--mobile) {
            .c-page-news .list-item__description p:nth-child(n + 2) {
              padding-top: 1rem; } }
        @media (--mobile) {
          .c-page-news .list-item__description p {
            margin-left: 0;
            padding-right: 0;
            font-size: 1.3rem; } }

.not-found {
  text-align: center;
  margin-top: 3rem; }

.p-store__heading .heading-logo svg {
  width: 18.1rem;
  height: 9.4rem;
  margin-top: 8.2rem;
  margin-right: auto;
  margin-left: auto; }
  @media (--mobile) {
    .p-store__heading .heading-logo svg {
      margin-top: 6.2rem; } }
  .p-store__heading .heading-logo svg path {
    fill: #430E12; }

.p-store__info {
  position: relative; }
  .p-store__info .info-inner {
    width: 85.5rem;
    margin-left: auto;
    margin-right: auto; }
    @media (--mobile) {
      .p-store__info .info-inner {
        width: 100%; } }

.p-store__img {
  padding-top: 9rem; }
  @media (--mobile) {
    .p-store__img {
      padding-top: 1.7rem; } }
  .p-store__img picture {
    width: calc(1300 / 1400 * 100%);
    height: auto; }
    @media (--mobile) {
      .p-store__img picture {
        width: calc(335 / 375 * 100%);
        height: 23rem; } }
    .p-store__img picture img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.p-store__reserve {
  margin-top: 12.7rem; }
  @media (--mobile) {
    .p-store__reserve {
      margin-top: 8.6rem; } }
  .p-store__reserve .reserve-ttl {
    font-size: 2.8rem; }
    @media (--mobile) {
      .p-store__reserve .reserve-ttl {
        font-size: 2.4rem; } }
  .p-store__reserve .reserve-way .way-content {
    display: flex;
    margin-top: 7rem;
    margin-top: 2.4rem; }
    @media (--mobile) {
      .p-store__reserve .reserve-way .way-content {
        margin-top: 0.8rem;
        flex-direction: column; } }
    .p-store__reserve .reserve-way .way-content p {
      line-height: 1; }
      @media (--mobile) {
        .p-store__reserve .reserve-way .way-content p {
          line-height: 2; } }
    .p-store__reserve .reserve-way .way-content__tel {
      padding-top: 1.5rem;
      padding-right: 6.1rem;
      padding-bottom: 1.5rem; }
      @media (--mobile) {
        .p-store__reserve .reserve-way .way-content__tel {
          padding-right: 0;
          padding-top: 2rem;
          padding-bottom: 2rem; } }
      .p-store__reserve .reserve-way .way-content__tel p:first-child {
        font-size: 2rem; }
        @media (--mobile) {
          .p-store__reserve .reserve-way .way-content__tel p:first-child {
            font-size: 1.8rem; } }
      .p-store__reserve .reserve-way .way-content__tel p:last-child {
        margin-top: 2em;
        font-size: 1.6rem; }
        @media (--mobile) {
          .p-store__reserve .reserve-way .way-content__tel p:last-child {
            font-size: 1.4rem;
            margin-top: 0rem; } }
      .p-store__reserve .reserve-way .way-content__tel a {
        display: block;
        margin-top: 2.4rem;
        color: #58574C; }
        @media (--mobile) {
          .p-store__reserve .reserve-way .way-content__tel a {
            margin-top: 0rem; } }
        .p-store__reserve .reserve-way .way-content__tel a span:first-child {
          font-size: 1.9rem; }
          @media (--mobile) {
            .p-store__reserve .reserve-way .way-content__tel a span:first-child {
              font-size: 1.7rem; } }
        .p-store__reserve .reserve-way .way-content__tel a span:last-child {
          font-size: 3.1rem;
          font-weight: bold; }
          @media (--mobile) {
            .p-store__reserve .reserve-way .way-content__tel a span:last-child {
              font-size: 2.8rem; } }
    .p-store__reserve .reserve-way .way-content__sns {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 8.6rem;
      border-left: 1px solid #dbdbdb; }
      @media (--mobile) {
        .p-store__reserve .reserve-way .way-content__sns {
          border-top: 1px solid #dbdbdb;
          padding-left: 0;
          border-left: 0px;
          padding-top: 2rem;
          padding-bottom: 2rem; } }
      .p-store__reserve .reserve-way .way-content__sns p:nth-child(2) {
        margin-top: 1.5rem;
        font-size: 1.4rem; }
      .p-store__reserve .reserve-way .way-content__sns p:last-child {
        display: flex;
        align-items: center;
        margin-top: 2.5rem; }
        @media (--mobile) {
          .p-store__reserve .reserve-way .way-content__sns p:last-child {
            justify-content: center; } }
        .p-store__reserve .reserve-way .way-content__sns p:last-child .sns-icon {
          display: flex;
          align-items: center; }
          .p-store__reserve .reserve-way .way-content__sns p:last-child .sns-icon svg {
            width: 2.4rem;
            height: 2.3rem;
            margin-right: 1rem; }
            .p-store__reserve .reserve-way .way-content__sns p:last-child .sns-icon svg path {
              fill: #58574C; }
          .p-store__reserve .reserve-way .way-content__sns p:last-child .sns-icon:nth-child(2) {
            margin-left: 4.5rem; }
            @media (--mobile) {
              .p-store__reserve .reserve-way .way-content__sns p:last-child .sns-icon:nth-child(2) {
                margin-left: 2rem; } }
  .p-store__reserve .reserve-way .way-caution {
    margin-top: 5rem; }
    @media (--mobile) {
      .p-store__reserve .reserve-way .way-caution {
        margin-top: 2rem; }
        .p-store__reserve .reserve-way .way-caution span {
          display: block; } }
  .p-store__reserve .reserve-customer {
    margin-top: 9.3rem; }
    @media (--mobile) {
      .p-store__reserve .reserve-customer {
        margin-top: 6.2rem; } }
    .p-store__reserve .reserve-customer ul {
      margin-top: 2.8rem;
      display: flex;
      flex-wrap: wrap; }
      @media (--mobile) {
        .p-store__reserve .reserve-customer ul {
          margin-top: 1rem; } }
      .p-store__reserve .reserve-customer ul li {
        width: 53.5rem;
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #DBDBDB; }
        @media (--mobile) {
          .p-store__reserve .reserve-customer ul li {
            padding-top: 2.4rem;
            padding-bottom: 2.4rem; } }
        @media (--large) {
          .p-store__reserve .reserve-customer ul li:nth-child(2n) {
            margin-left: 3rem; } }
        .p-store__reserve .reserve-customer ul li span {
          line-height: 2; }
          .p-store__reserve .reserve-customer ul li span:first-child {
            font-size: 1.4rem;
            color: #A5A5A5;
            margin-right: 2rem; }

.p-top__mv {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #F4F3F2; }
  .p-top__mv-logo {
    width: 13.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: blur(10px);
    opacity: 0; }
    .p-top__mv-logo svg {
      width: 100%;
      height: auto; }
      .p-top__mv-logo svg path {
        fill: #58574C; }
  .p-top__mv .mv-img {
    width: 100%;
    height: 100%;
    opacity: 0; }
    .p-top__mv .mv-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .p-top__mv .mv-ttl {
    width: 48.8rem;
    height: 23rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 4rem;
    text-align: center;
    line-height: 1.7;
    margin-top: 1.4rem;
    letter-spacing: 0.1em;
    opacity: 0; }
    @media (--mobile) {
      .p-top__mv .mv-ttl {
        font-size: 3.2rem;
        margin-top: 0;
        width: 29rem; } }
    .p-top__mv .mv-ttl img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain; }
      .p-top__mv .mv-ttl img.is-after {
        opacity: 0; }
  .p-top__mv .mv-copy {
    width: 36.2rem;
    height: 28.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .p-top__mv .mv-copy__inner {
      position: relative;
      width: 100%;
      height: 100%; }
      .p-top__mv .mv-copy__inner p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) translateY(-8rem);
        display: inline-block;
        color: #fff;
        font-weight: 500;
        line-height: 1;
        padding-bottom: 1.4rem;
        position: relative; }
        @media (--mobile) {
          .p-top__mv .mv-copy__inner p {
            transform: translate(-50%, -50%) translateY(-7rem); } }
        .p-top__mv .mv-copy__inner p::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background: rgba(255, 255, 255, 0.3);
          bottom: 0; }
    .p-top__mv .mv-copy__bg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60.8rem;
      height: 24.4rem;
      opacity: 0; }
      @media (--mobile) {
        .p-top__mv .mv-copy__bg {
          width: 100%;
          height: 13rem;
          margin-left: 2rem;
          top: 46%; } }
  .p-top__mv .mv-scroll {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem; }
    @media (--mobile) {
      .p-top__mv .mv-scroll {
        bottom: 0; } }
    .p-top__mv .mv-scroll p {
      font-size: 1.2rem;
      color: #fff;
      margin-bottom: 1rem; }
    .p-top__mv .mv-scroll__bar {
      display: block;
      width: 1px;
      height: 3rem;
      overflow: hidden;
      position: relative;
      margin-inline: auto; }
      @media (--mobile) {
        .p-top__mv .mv-scroll__bar {
          height: 2rem; } }
      .p-top__mv .mv-scroll__bar::before {
        content: '';
        display: block;
        width: 100%;
        height: 600%;
        background-color: white;
        position: absolute;
        left: 0;
        top: -600%;
        animation: barAnimation 4s infinite linear; }

@keyframes barAnimation {
  0% {
    top: -600%; }
  100% {
    top: 100%; } }

.p-top__heading {
  padding-top: 10rem;
  padding-bottom: 10rem; }
  @media (--mobile) {
    .p-top__heading {
      padding-top: 8.7rem;
      padding-bottom: 8rem;
      overflow: hidden; } }
  .p-top__heading .heading-inner {
    width: 43rem;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
    @media (--mobile) {
      .p-top__heading .heading-inner {
        width: 100%; } }
  .p-top__heading .heading-ttl {
    text-align: center;
    font-size: 2.8rem; }
    @media (--mobile) {
      .p-top__heading .heading-ttl {
        font-size: 2.4rem;
        line-height: 1.75; } }
  .p-top__heading .heading-text {
    margin-top: 6.8rem; }
    .p-top__heading .heading-text p:nth-child(n + 2) {
      margin-top: 3.6rem;
      margin-bottom: 7.5rem; }
  .p-top__heading .heading-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateX(24rem);
    width: 41.1rem;
    height: 61.6rem;
    z-index: -1; }
    @media (--mobile) {
      .p-top__heading .heading-img {
        width: 41rem;
        height: 61.6rem;
        left: auto;
        right: 0;
        transform: translateX(9rem) translateY(-50%); } }
    .p-top__heading .heading-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.p-top__cook-img {
  width: 100%;
  height: 51rem; }
  @media (--mobile) {
    .p-top__cook-img {
      height: 23rem; } }
  .p-top__cook-img picture {
    width: calc(1300 / 1400 * 100%);
    height: 100%; }
    @media (--mobile) {
      .p-top__cook-img picture {
        width: calc(335 / 375 * 100%); } }
  .p-top__cook-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.p-top__menu .menu-inner {
  margin-top: 10rem;
  padding-top: 22.3rem;
  padding-bottom: 56.2rem;
  margin-bottom: 9.6rem;
  position: relative; }
  @media (--mobile) {
    .p-top__menu .menu-inner {
      margin-top: 8.6rem;
      margin-bottom: 8rem;
      padding-top: 0;
      padding-bottom: 0; } }

.p-top__menu .menu-text {
  margin-top: 6.7rem;
  margin-bottom: 6.5rem;
  width: 43.2rem; }
  @media (--mobile) {
    .p-top__menu .menu-text {
      margin-top: 4.3rem;
      width: 100%; } }

.p-top__menu .menu-img {
  position: absolute; }
  .p-top__menu .menu-img-01 {
    right: 0;
    top: 0;
    width: 57.5rem;
    height: 93rem; }
    @media (--mobile) {
      .p-top__menu .menu-img-01 {
        width: 18.7rem;
        height: 33rem; } }
  .p-top__menu .menu-img-02 {
    left: 0;
    bottom: 0;
    width: 50.8rem;
    height: 31.4rem; }
  .p-top__menu .menu-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.p-top__menu-img {
  position: relative;
  height: 51.5rem;
  margin-bottom: 8.6rem; }
  .p-top__menu-img .menu-img {
    position: absolute; }
    .p-top__menu-img .menu-img-01 {
      right: 0;
      top: 0;
      width: 18.7rem;
      height: 33rem; }
    .p-top__menu-img .menu-img-02 {
      left: 0;
      bottom: 0;
      width: 23.6rem;
      height: 14.5rem; }
    .p-top__menu-img .menu-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.p-top__news .news-inner {
  width: 85.5rem;
  margin-right: auto;
  margin-left: auto; }
  @media (--mobile) {
    .p-top__news .news-inner {
      width: 100%; } }

.p-top__news .news-list {
  margin-top: 3rem;
  margin-bottom: 6.3rem; }
  @media (--mobile) {
    .p-top__news .news-list {
      margin-top: 1rem;
      margin-bottom: 4.8rem; } }

.p-top__news .news-item a {
  border-bottom: 1px solid #DBDBDB;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 5rem;
  display: flex;
  align-items: center; }
  @media (--mobile) {
    .p-top__news .news-item a {
      flex-direction: column;
      align-items: start; } }
  .p-top__news .news-item a time {
    margin-right: 4rem;
    font-size: 1.2rem;
    color: #A5A5A5; }
    @media (--mobile) {
      .p-top__news .news-item a time {
        margin-bottom: 0.5rem;
        font-size: 1.4rem; } }
  .p-top__news .news-item a svg {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.75rem;
    height: 0.38rem; }
    @media (--mobile) {
      .p-top__news .news-item a svg {
        top: initial;
        transform: initial;
        bottom: 4rem; } }

.p-top__shop-img {
  height: 48rem;
  width: 100%;
  position: relative;
  margin-top: 11rem;
  margin-bottom: 11.5rem; }
  @media (--mobile) {
    .p-top__shop-img {
      margin-top: 14rem;
      margin-bottom: 0rem; } }
  @media (--mobile) {
    .p-top__shop-img {
      height: 38rem; } }
  .p-top__shop-img .shop-img {
    position: absolute; }
    .p-top__shop-img .shop-img-01 {
      width: 22rem;
      height: 35.6rem;
      top: 0;
      left: 0; }
      @media (--mobile) {
        .p-top__shop-img .shop-img-01 {
          width: 10.1rem;
          height: 16.3rem; } }
    .p-top__shop-img .shop-img-02 {
      width: 39.5rem;
      height: 24.4rem;
      left: 27.3rem;
      top: 23.4rem; }
      @media (--mobile) {
        .p-top__shop-img .shop-img-02 {
          left: 0;
          width: 18.1rem;
          height: 11.2rem; } }
    .p-top__shop-img .shop-img-03 {
      top: 6rem;
      right: 0;
      width: 51.3rem;
      height: 31.8rem; }
      @media (--mobile) {
        .p-top__shop-img .shop-img-03 {
          width: 22.4rem;
          height: 15.1rem; } }
    .p-top__shop-img .shop-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }

.p-top__store {
  margin-bottom: 10rem; }
  @media (--mobile) {
    .p-top__store {
      margin-top: -4rem; } }
  .p-top__store .store-inner {
    width: 85.5rem;
    margin-left: auto;
    margin-right: auto; }
    @media (--mobile) {
      .p-top__store .store-inner {
        width: 100%; } }
  .p-top__store .store-info {
    margin-top: 3rem; }

.c-top-ttl {
  font-size: 2.1rem;
  position: relative; }
  .c-top-ttl::before {
    top: 50%;
    transform: translateY(-50%);
    left: -4.5rem;
    position: absolute;
    content: "";
    width: 3.3rem;
    height: 0.1rem;
    background-color: #DBDBDB; }
  .c-top-ttl span {
    font-size: 1.2rem;
    margin-left: 2rem; }
