@media (--large) {
  .text-hov{
    svg{
      transition: 0.5s;
      path{
        transition: 0.3s;
      }
    }
    &:hover{
      svg{
        transform: translateX(1rem);
        path{
          stroke: #58574C;
        }
      }
    }
  }

  .icon-hov{
    svg{
      transition: 0.3s;
    }

    &:hover{
      svg{
        transform: translateY(-0.3rem);
      }
    }
  }

  .footer-hov{
    transition: 0.8s;
    border-bottom: 1px solid transparent;
    padding-bottom: 0.5rem;

    &:hover{
      border-bottom: 1px solid #fff;
      text-shadow: 1px 1px 1px #58574C;
    }
  }

}
