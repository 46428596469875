.l-footer {
  &__reserve {
    .reserve {
      &-inner {
        position: relative;
      }

      &-img {
        width: 100%;
        height: 52rem;
        @media (--mobile) {
          // height: 20.8rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-content{
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 100%;
        justify-content: center;
        padding-top: 6.5rem;
        padding-bottom: 6rem;

        @media (--mobile) {
          // background-color: #430E12;
          // position: initial;
          // transform: initial;
        }

        h3{
          font-size: 2.4rem;
          margin-bottom: 10rem;
          @media (--mobile) {
            font-size: 1.8rem;
            margin-bottom: 3.4rem;
          }
        }
      }

      &-content__inner{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        @media (--mobile) {
          flex-direction: column;
        }

      }

      &-text {

        padding-right: 10rem;
        padding-left: 10rem;


        @media (--mobile) {
          padding-right: 0;
          padding-left: 0;
          padding-bottom: 3rem;
        }

        p {
          line-height: 1;
        }

        &__ttl {
          position: relative;
          padding-bottom: 3rem;
          font-size: 2rem;

          @media (--mobile) {
            font-size: 1.8rem;
            padding-bottom: 2.1rem;
          }

          &::before {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 7.2rem;
            height: 0.1rem;
            content: "";
            background: rgba($color: #fff, $alpha: 0.3);
            transform: translateX(-50%);
          }
        }

        &__tel {
          margin-top: 2.74rem;
          display: block;
          color: #fff;

          @media (--mobile) {
            margin-top: 1.5rem;
          }

          span {
            &:first-child {
              font-size: 1.9rem;
              @media (--mobile) {
                font-size: 1.7rem;
              }
            }

            &:nth-child(2) {
              margin-left: 0.7rem;
              font-size: 3.4rem;

              @media (--mobile) {
                font-size: 2.9rem;
              }
            }
          }
        }

        &__time {
          margin-top: 1.36rem;
          font-size: 1.6rem;
          @media (--mobile) {
            margin-top: 1.5em;
            font-size: 1.4rem;
          }
        }

        &__caution {
          font-size: 2rem;

          @media (--mobile) {
            font-size: 1.4rem;
            margin-top: 1.6rem;
          }
        }
      }

      &-sns{
        padding-left: 10rem;

        border-left: 1px solid rgba($color: #fff, $alpha: 0.3);
        @media (--mobile) {
          border-left: 0;
          padding-left: 0;
          padding-top: 3rem;
          border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
        }
        &__text{
          text-align: left;
          @media (--mobile) {
            font-size: 1.2rem;
          }
          span{
            display: block;
            &:nth-child(n + 2){
              font-size: 1.4rem;
              @media (--mobile) {
                font-size: 1.2rem;
              }
            }
          }
        }

        &__link{
          display: flex;
          margin-top: 3.7rem;

          @media (--mobile) {
            justify-content: center;
            margin-top: 2.8rem;
          }

          a{
            display: flex;
            align-items: center;

            &:nth-child(n + 2){
              margin-left: 4.5rem;
              @media (--mobile) {
                margin-left: 2.4rem;
              }
            }

            span{
              color: #fff;
              margin-left: 1.5rem;
            }
            svg{
              width: 2.38rem;
              height: 2.28rem;
              path{
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }

  &__body {
    height: 25rem;
    padding-top: 5.4rem;
    padding-bottom: 6.3rem;
    background-color: #430E12;
    position: relative;
    @media (--mobile) {
      height: 38rem;
    }
    // &::after {
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 1.5rem;
    //   background-color: #343434;
    //   opacity: 0.9;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   mix-blend-mode: multiply;
    // }

    .body {
      &-inner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (--mobile) {
          flex-direction: column;
        }
      }

      &-left {
        &__logo {
          width: 8.4rem;
          height: 11rem;
          display: block;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &-right {
        &__list {
          display: flex;
          @media (--mobile) {
            flex-wrap: wrap;
            margin-top: 4rem;
          }

          li {
            @media (--large) {
              &:nth-child(n + 2) {
                margin-left: 3rem;
              }
            }
            @media (--mobile) {
              width: 50%;
              text-align: center;

              &:nth-child(n + 3){
                margin-top: 3rem;
              }
            }

            a {
              font-size: 1.6rem;
              color: #fff;
            }
          }
        }
      }

      &-sns {
        position: absolute;
        bottom: -6rem;
        left: 0;
        display: flex;
        margin-top: 2.7rem;
        a{
          display: inline-block;
          &:nth-child(n + 2){
            margin-left: 1.5rem;
          }
        }

        @media (--mobile) {
          bottom: -10rem;
        }

        svg {
          width: 2.7rem;
          height: 2.7rem;
          @media (--mobile) {
            width: 2.2rem;
            height: 2.2rem;
          }
          path{
            fill: #fff;
          }
        }
      }

      &-copy {
        position: absolute;
        right: 0;
        bottom: -6rem;
        font-size: 1.1rem;
        color: #fff;
        @media (--mobile) {
          bottom: -10rem;
        }
      }
    }
  }
}
