.p-commitment {
  padding-top: 0 !important;

  &__video{
    width: 100%;
    max-height: 86rem;
    height: 100vh;
    @media (--mobile) {
      height: 30rem;
    }
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__mv{
    width: 100%;
    max-height: 86rem;
    height: 100vh;
    @media (--mobile) {
      height: 30rem;
    }
  }

  &__heading {
    margin-top: 10rem;

    @media (--mobile) {
      margin-top: 6rem;
    }

    .heading {

      &-inner {
        position: relative;
        width: 65rem;
        margin-right: auto;
        margin-left: auto;

        @media (--mobile) {
          width: 100%;
        }
      }

      &-text {
        margin-top: 10.6rem;

        @media (--mobile) {
          margin-top: 6.2rem;
        }

        p {
          &:nth-child(n + 2) {
            margin-top: 4.6rem;

            @media (--mobile) {
              margin-top: 2.8rem;
            }
          }
        }
      }

      &-message {
        display: flex;
        align-items: center;
        margin-top: 8.2rem;

        @media (--mobile) {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 2em;
        }

        .message {
          &-logo {
            svg {
              width: 14.2rem;
              height: 7.4rem;

              @media (--mobile) {
                width: calc(14.2rem * 0.7);
                height: calc(7.4rem * 0.7);
              }

              path {
                fill: #430E12;
              }
            }
          }

          &-text {
            margin-left: 5rem;

            @media (--mobile) {
              margin-top: 0.5rem;
              margin-left: 0;
            }
          }
        }
      }

      &-video {
        margin-top: 15.2rem;

        @media (--mobile) {
          margin-top: 4rem;
        }
      }
    }
  }

  &__contents {
    .contents {
      &-concept {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15.6rem;

        @media (--mobile) {
          flex-direction: column;
          margin-top: 8rem;
        }

        &__left {
          width: 43rem;
          @media (--mobile) {
            width: 100%;
          }
          h2 {
            position: relative;
            display: flex;
            align-items: center;
            font-size: 2.1rem;

            &::before {
              position: absolute;
              top: 50%;
              left: -4.5rem;
              width: 3.3rem;
              height: 0.1rem;
              content: "";
              background-color: #dbdbdb;
              transform: translateY(-50%);
              transform: translateY(-50%);
            }

            small {
              margin-left: 2rem;
              font-size: 1.2rem;
            }
          }
          p{
            margin-top: 5.3rem;
            @media (--mobile) {
              margin-top: 3.2rem;
            }
            &:nth-of-type(n + 2){
              margin-top: 1.5rem;
            }
          }
        }

        &__right{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 52rem;
          height: 34.7rem;
          background-color: white;

          @media (--mobile) {
            margin-top: 2.4rem;
            width: 100%;
          }
          svg{
            width: 18.6rem;
            height: 24.3em;
            path{
              fill: #430E12;
            }
          }
        }
      }
    }
  }
}
